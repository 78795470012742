import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Form, Image, Input, PageHeader, Skeleton, Spin, Upload, message, Dropdown, Anchor } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Page from "../../components/Page";
import EditorComponent from "../../components/quill";

const BranchEdit = () => {
  const [Branch, setBranch] = useState(null);
  const [area, setArea] = useState([]);
  const [loading, setLoading] = useState(true);
  const { TextArea } = Input;
  const [submitLoading, setSubmitLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const deleteItem = (data) => {
    setSubmitLoading(true);
    axios({
      method: 'DELETE',
      url: `/admin/branch/${id}`,
    //   data: data
    }).then((res) => {
      if (res.data.success) {
        alert('삭제 성공!');
        setSubmitLoading(false);
        navigate('/branches')
      }
    });
  };

  const editBranch = (data) => {
    setSubmitLoading(true);
    console.log(data)
    axios({
      method: 'POST',
      url: `/admin/branch/${id}`,
      data: {...data, area}
    }).then((res) => {
      if (res.data.success) {
        alert("수정완료")
        setSubmitLoading(false);
        navigate('/branches');
      }
    });
  };

  useEffect(() => {
    axios({
      method: 'GET',
      url: `/admin/branch/${id}`
    }).then(res => {
      if (res.data.success) {
        console.log(res.data)
        setBranch(res.data.areas[0]);
        setArea(res.data.areas.map((item)=>{return {...item, origin:true}}))
        setLoading(false);
      }
    })
  }, [id]);

  return (
    <Page pageName="branches">
      <PageHeader title="지사 관리" />
      {
        Branch === null || loading ? (
          <Skeleton />
        ) : (
          <Form 
            name="histories"
            autoComplete="off"
            initialValues={Branch}
            onFinish={editBranch}
          >
            <Form.Item
              label="지사명"
              name="name"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="지사코드"
              name="jisacode"
            >
              <Input />
            </Form.Item>
            {area.map((item,idx)=>{
              return (
                <div id={idx} key={idx} style={{'display':'flex','alignItems':'center','marginLeft':'10px'}}>
                <CloseOutlined id={idx} key={idx} onClick={(e)=>{setArea(area.filter((data)=>{if(data.id !== item.id ){return data;}}));console.log(item)}}/>   
                <Input placeholder="예: 서울특별시" onChange={(e)=>{setArea(area.map((iitem)=>{return {...iitem, city: item.id === iitem.id? e.target.value : iitem.city}}))}} value={item.city} style={{'width':'300px','marginLeft':'30px', 'marginBottom':'2px',}}/>
                <Input placeholder="예: 용산구" onChange={(e)=>{setArea(area.map((iitem)=>{return {...iitem, area: item.id === iitem.id? e.target.value : iitem.area}}))}} value={item.area} style={{'width':'300px','marginLeft':'10px', 'marginBottom':'2px',}}/>
                </div>
              )
            })}
            <Form.Item>
              <Button style={{'marginRight':'10px','marginTop':'30px','backgroundColor':'orange', borderColor:'orange'}} type="primary" onClick={()=>{setArea([...area,{id: area.length}])}}>{ submitLoading ? <Spin /> : '지역 추가' }</Button>
              <Button style={{'marginTop':'30px'}} type="primary" htmlType="submit">{ submitLoading ? <Spin /> : '저장' }</Button>
              <Button style={{'marginLeft':'10px'}} type="primary" danger onClick={() => { deleteItem(id) }}>{ submitLoading ? <Spin /> : '삭제' }</Button>
            </Form.Item>
            <Anchor affix={false}>
                {/* setBanner({...Banner, files: Banner.files.filter((item)=>{if(item.target)})}) */}
            </Anchor>
          </Form>
          
        )
      }
    </Page>
  )
};

export default BranchEdit;