import { Button, PageHeader, Table, Image, Switch, Checkbox} from "antd";
import axios from "axios";
import moment from "moment";
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { MenuOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Page from "../../components/Page";


const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

const SortableItem = SortableElement((props) => (
  <tr {...props} />
));
const SortableBody = SortableContainer((props) => (
  <tbody {...props} />
));

const BannerList = () => {
  const [Banners, setBanners] = useState([]);
  const [recommendBanners, setRecommendBanners] = useState([]);
  const [filter, setFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(()=>{
    setLoading(true)
    const filterQuery = filter? '?filter=recommend' : ''
    axios({
      method: 'GET',
      url: `/admin/banner${filterQuery}`
    }).then(res => {
      if (res.data.success) {
        setBanners(res.data.banners.map((item, idx)=>{
          const lengthLimit = item.title.length > 20? "..." : "" 
          return{...item, createdAt: moment(item.createdAt).format('YYYY-MM-DD시 HH:mm분'), title: (item.title.slice(0,25)+lengthLimit) , orders: idx+1,index: idx+1}}));
        setLoading(false);  
        } 
    })
  },[filter])
  
  const reorder = (data) =>{
    console.log(data)
    setLoading(true)
    axios({
      method: 'POST',
      url: `/admin/banner/order`,
      data: data
    }).then(res => {
      if (res.data.success) {
        setLoading(false);  
        } 
    })
  }

  const columns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: '고유 번호',
      dataIndex: 'id',
      key: 'id'
    },
    // {
    //   title: '추천 여부',
    //   render: (_, record) => (
    //     <Checkbox checked={record.recommend} />
    //     )
    // },
    {
      title: '노출 여부',
      render: (_, record) => (
        <Checkbox checked={record.expose} />
        )
    },
    {
      title: '노출 순서',
      dataIndex: 'orders',
      key: 'orders'
    },
    {
      title: '유형',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: '제목',
      key: 'title',
      dataIndex: 'title',
    },
    {
      title: '생성일자',
      key: 'createdAt',
      dataIndex: 'createdAt',
    },
    {
      title: '대표 사진',
      render: (_, record) => (
        <Image style={{'width':'70px'}} src={record.thumbnail} />
      )
    },
    {
      title: '',
      render: (_, record) => (
        <Button onClick={() => { navigate(`/banner/${record.id}`) }}>수정</Button>
      )
    },
  ];

  useEffect(() => {
    axios({
      method: 'GET',
      url: '/admin/banner'
    }).then(res => {
      if (res.data.success) {
        setBanners(res.data.banners.map((item, idx)=>{return{...item, createdAt: moment(item.createdAt).format('YYYY-MM-DD시 HH:mm분'), orders: idx+1 ,index: idx+1}}));
        setRecommendBanners(res.data.banners.filter((item)=>{if(item.recommend){return item}}).map((item, idx)=>{return{...item, createdAt: moment(item.createdAt).format('YYYY-MM-DD시 HH:mm분'), orders: idx+1 ,index: idx+1}}));
        setLoading(false);
        }
    })
  }, []);
  
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(Banners.slice(), oldIndex, newIndex).filter(
        (el) => !!el,
      );
      setBanners(newData.map((item, idx)=>{return {...item, orders: idx+1, index: idx+1}}))
      reorder(newData.map((item, idx)=>{return {...item, orders: idx+1, index: idx+1}}))
    }
  };
  
  const onRecommendSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(recommendBanners.slice(), oldIndex, newIndex).filter(
        (el) => !!el,
      );
      setRecommendBanners(newData.map((item, idx)=>{return {...item, orders: idx+1, index: idx+1}}))
      reorder(newData.map((item, idx)=>{return {...item, orders: idx+1, index: idx+1}}))
    }
  };

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableRecommendContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onRecommendSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = Banners.findIndex(x => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  const DraggableRecomendBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = recommendBanners.findIndex(x => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <Page pageName="banners">
        <PageHeader title="뮤엠 소식 관리" />
      {/* <Table dataSource={Banners} columns={columns} loading={loading} /> */}
      <Table
      style={{'marginBottom':'20px'}}
      pagination={false}
      dataSource={Banners}
      loading={loading}
      columns={columns}
      rowKey="index"
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
    />
          <PageHeader title="추천 소식 관리" />
      <Table
      style={{'marginBottom':'20px'}}
      pagination={false}
      dataSource={recommendBanners}
      loading={loading}
      columns={columns}
      rowKey="index"
      components={{
        body: {
          wrapper: DraggableRecommendContainer,
          row: DraggableRecomendBodyRow,
        },
      }}
    />
      <Button type="primary" onClick={() => { navigate('/banner/add') }}>추가</Button>
      {/* <Switch style={{'marginLeft':'20px'}} checked={filter} onClick={() => { setFilter(filter? false: true) }}></Switch>
      <Button style={{'marginLeft':'10px', 'backgroundColor':filter?'black':'green', 'border':'none'}} type="primary" onClick={() => { setFilter(filter? false: true) }}>추천 자료 필터링</Button> */}
    </Page>
  )
};

export default BannerList;