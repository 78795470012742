import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Image, Input, PageHeader, Skeleton, Spin, Upload, message, Dropdown } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Page from "../../components/Page";
import EditorComponent from "../../components/quill";

const HistoryEdit = () => {
  const [History, setHistory] = useState(null);
  const [loading, setLoading] = useState(true);
  const { TextArea } = Input;
  const [submitLoading, setSubmitLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const deleteItem = (data) => {
    setSubmitLoading(true);
    axios({
      method: 'DELETE',
      url: `/admin/history/${id}`,
    //   data: data
    }).then((res) => {
      if (res.data.success) {
        alert('삭제 성공!');
        setSubmitLoading(false);
        navigate('/histories')
      }
    });
  };

  const editHistory = (data) => {
    setSubmitLoading(true);

    const formData = new FormData();
    const {
      name,
      phoneNumber,
      email,
      education,
      educationStatus,
      educationYear,
      institution,
      institutionName
    } = data;

    formData.append('name', name);
    formData.append('phoneNumber', phoneNumber);
    formData.append('email', email);
    formData.append('education', education);
    formData.append('educationStatus', educationStatus);
    formData.append('educationYear', educationYear);
    formData.append('institution', institution);
    formData.append('institutionName', institutionName);

    axios({
      method: 'POST',
      url: `/admin/history/${id}`,
      data: data
    }).then((res) => {
      if (res.data.success) {
        alert("수정완료")
        setSubmitLoading(false);
        navigate('/histories');
      }
    });
  };

  useEffect(() => {
    axios({
      method: 'GET',
      url: `/admin/history/${id}`
    }).then(res => {
      if (res.data.success) {
        console.log(res.data)
        setHistory(res.data.history);
        setLoading(false);
      }
    })
  }, [id]);

  return (
    <Page pageName="histories">
      <PageHeader title="연혁 관리" />
      {
        History === null || loading ? (
          <Skeleton />
        ) : (
          <Form 
            name="histories"
            autoComplete="off"
            initialValues={History}
            onFinish={editHistory}
          >
            <Form.Item
              label="년도"
              name="year"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="월"
              name="month"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="내용"
              name="contents"
            >
            <EditorComponent />
            {/* <EditorComponent value={content} onChange={setContent} /> */}
            {/* <TextArea/> */}
              {/* <Input type={'text'} /> */}
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">{ submitLoading ? <Spin /> : '저장' }</Button>
              <Button style={{'marginLeft':'10px'}} type="primary" danger onClick={() => { deleteItem(id) }}>삭제</Button>
            </Form.Item>
          </Form>
        )
      }
    </Page>
  )
};

export default HistoryEdit;