import { Button, Form, Image, Input, PageHeader, Select, Skeleton, Spin } from "antd";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Page from "../../../components/Page";

const HomeRollingAdd = () => {
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [HomeInformation, setHomeInformation] = useState({type:""});
  const navigate = useNavigate();

  const addImage = async (item) => {
    const formData = new FormData();
    formData.append('file', item);
    return axios.post('/file',formData).then(res => {
      alert('사진 등록 성공')
      setHomeInformation({...HomeInformation, thumbnail: res.data.url})
    }).catch(err => {
      alert('실패')
    })
  }

  const addImageMobile = async (item) => {
    const formData = new FormData();
    formData.append('file', item);
    return axios.post('/file',formData).then(res => {
      alert('사진 등록 성공')
      setHomeInformation({...HomeInformation, mobileThumbnail: res.data.url})
    }).catch(err => {
      alert('실패')
    })
  }


  const addTag = (data) => {
    console.log(HomeInformation)
    console.log(data)
    if(!HomeInformation['mobileThumbnail']||!HomeInformation['thumbnail']||!HomeInformation['orders']||!HomeInformation['url']){
      alert('제대로 입력되지 않았습니다!')
    }else {
      axios({
        url: '/admin/rolling',
        method: 'POST',
        data: {...HomeInformation, orders: parseInt(HomeInformation.orders)}
      }).then(() => {
        setLoading(false);
        alert('등록완료!')
        navigate('/information/home');
      });
    }
    
  };

  useEffect(() => {
    setHomeInformation();
    setLoading(false)
  }, []);

  return (
    <Page pageName="homeInformation">
      <PageHeader title="롤링 배너 관리" />
      {
        HomeInformation === null || loading ? (
          <Skeleton />
        ) : (
          <Form 
            name="homeInformation"
            autoComplete="off"
            initialValues={HomeInformation}
            onFinish={addTag}
          >
            <Form.Item
              label="순서"
              name="orders"
            >
            <Input value={HomeInformation?.orders} onChange={(e)=>{ setHomeInformation({...HomeInformation, orders: e.target.value})}} />
            </Form.Item>
            <Form.Item
              label="썸네일(1920*533)"
              name="thumbnail"
            >
            {/* <Input/> */}
            <Image width={200} src={HomeInformation?.thumbnail} />
            <input
                type="file"
                onChange={(event) => {
                  if (event.target.files.length) {
                    addImage(event.target.files[0])
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label="모바일 썸네일(360 * 236)"
              name="mobileThumbnail"
            >
            {/* <Input/> */}
            <Image width={200} src={HomeInformation?.mobileThumbnail} />
            <input
                type="file"
                onChange={(event) => {
                  if (event.target.files.length) {
                    addImageMobile(event.target.files[0])
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label="링크 주소"
              name="url"
            >
            <Input value={HomeInformation?.url} onChange={(e)=>{ setHomeInformation({...HomeInformation, url: e.target.value})}} />
            {/* <Input/> */}
            </Form.Item>
            {/* <Form.Item
              label="비고"
              name="memo"
            >
            <Input/>
            </Form.Item> */}
            <Form.Item>
              <Button type="primary" htmlType="submit">{ submitLoading ? <Spin /> : '저장' }</Button>
            </Form.Item>
          </Form>
        )
      }
    </Page>
  )
};

export default HomeRollingAdd;