import { CloseOutlined } from "@ant-design/icons";
import { Button, Form, Input, PageHeader, Skeleton, Spin, Image, Select, Typography, Anchor, Checkbox } from "antd";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Page from "../../components/Page";
import EditorComponent from "../../components/quill";

const BannerAdd = () => {
    const navigate = useNavigate();
  const [Banner, setBanner] = useState(null);
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState('');
  const { TextArea } = Input;
  const { Link } =  Anchor;
  const [submitLoading, setSubmitLoading] = useState(false);
  const { Title } = Typography;
  const addImage = async (item) => {
    const formData = new FormData();
    formData.append('file', item);
    return axios.post('/file',formData).then(res => {
      alert('사진 등록 성공')
      setBanner({...Banner, thumbnail: res.data.url, content})
    }).catch(err => {
      alert('실패')
    })
  }

  const addImageMobile = async (item) => {
    const formData = new FormData();
    formData.append('file', item);
    return axios.post('/file',formData).then(res => {
      alert('사진 등록 성공')
      setBanner({...Banner, mobileThumbnail: res.data.url, content})
    }).catch(err => {
      alert('실패')
    })
  }

  const addFile = async (item) => {
    const formData = new FormData();
    formData.append('file', item);
    return axios.post('/file',formData).then(res => {
      const files = { name: res.data.originalName, contents: res.data.url, extend: res.data.extension, success: undefined};
      alert('파일 등록 성공!')
      setBanner({...Banner, files: [...Banner.files,files]})
    }).catch(err => {
      alert('실패')
    })
  }

  const editBanner = (data) => {
    console.log(Banner)
    setSubmitLoading(true);
    axios({
      method: 'POST',
      url: `/admin/banner`,
      data: Banner
    }).then((res) => {
      if (res.data.success) {
        setSubmitLoading(false);
        alert('등록 됐습니다!')
        navigate('/banners')
      } else {
          alert('정보가 이상해요')
          setSubmitLoading(false);
      }
    });
  };

  useEffect(() => {
    setBanner({files:[]});
    setLoading(false)
  }, []);

  return (
    <Page pageName="banners">
      <PageHeader title="뮤엠 소식 관리" />
      {
        Banner === null || loading ? (
          <Skeleton />
        ) : (
          <Form 
            name="banners"
            autoComplete="off"
            initialValues={Banner}
            onFinish={editBanner}
          >
            <Form.Item
              label="제목"
              name="title"
            >
              {/* <Input /> */}
              <TextArea onChange={(e)=>{setBanner({...Banner, title: e.target.value})}}/>
            </Form.Item>
            <Form.Item
              label="내용"
              name="content"
            >
              {/* <Input type={'text'} onChange={(e)=>{setBanner({...Banner, content: e.target.value})}}/> */}
            </Form.Item>
            <EditorComponent value={content} onChange={setContent} />
            <Form.Item
              label="유형"
              name="type"
            >
              <Select onChange={(e)=>{setBanner({...Banner, type: e})}}>
                <option value="인터뷰">인터뷰</option>
                <option value="이벤트">이벤트</option>
                <option value="보도자료">보도자료</option>
                <option value="공지사항">공지사항</option>
              </Select>
            </Form.Item>
            <Form.Item
              label="PC/태블릿 썸네일 ( px: 389 X 389 )"
              name="thumbnail"
            >
              {Banner?
              (<Image width={200} src={Banner.thumbnail} />):
              (<div></div>)}
              <input
                type="file"
                onChange={(event) => {
                  if (event.target.files.length) {
                    addImage(event.target.files[0])
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label="모바일 썸네일 ( px: 316 X 189 )"
              name="mobileThumbnail"
              style={{'marginLeft':'20px'}}
            >
              {Banner?
              (<Image width={200} src={Banner.mobileThumbnail} />):
              (<div></div>)}
              <input
                type="file"
                onChange={(event) => {
                  if (event.target.files.length) {
                    addImageMobile(event.target.files[0])
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label="첨부 파일"
              name="thumbnail"
            >

              <input
                type="file"
                onChange={(event) => {
                  if (event.target.files.length) {
                    addFile(event.target.files[0])
                  }
                }}
              />
            </Form.Item>
            <Anchor affix={false}>
            {Banner.files.map((item,idx)=>{
              return (
                <div id={idx} key={idx} style={{'display':'flex','alignItems':'center','marginLeft':'10px'}}>
                <CloseOutlined id={idx} key={idx} onClick={(e)=>{setBanner({...Banner, files: Banner.files.filter((data)=>{if(data.contents !== item.contents ){return data;}})});console.log(item)}}/>              
                <Link href={item.contents} title={item.name}   />              
                </div>
              )
            })}

            </Anchor>
            <Form.Item
              label="게시물 노출 순서"
              name="orders"
            >
            <Input value={Banner.orders} onChange={(e)=>{setBanner({ ...Banner, orders: e.target.value})}}/>
            </Form.Item>
            <Form.Item
              label="게시물 노출 여부"
              name="expose"
            >
            <Checkbox checked={Banner.expose} onChange={(e)=>{setBanner({...Banner, expose: Banner.expose? false: true})}} />
            </Form.Item>
            <Form.Item
              label="추천여부"
              name="recommend"
            >
            <Checkbox checked={Banner.recommend} onChange={(e)=>{setBanner({...Banner, recommend: Banner.recommend? false: true})}} />
            </Form.Item>
            <Form.Item
              label="예약발행"
              name="reservation"
            >
            <Input type={'datetime-local'}/>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">{ submitLoading ? <Spin /> : '저장' }</Button>
            </Form.Item>
          </Form>
        )
      }

    </Page>
  )
};

export default BannerAdd;