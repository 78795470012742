import { Button, Form, Input, PageHeader, Skeleton, Spin } from "antd";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Page from "../../components/Page";
import EditorComponent from "../../components/quill";

const HistoryAdd = () => {
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading]= useState(false);
  const navigate = useNavigate();

  const editHistory = (data) => {
    axios({
      url: '/admin/history',
      method: 'POST',
      data
    }).then(() => {
      setLoading(false);
      alert("등록 성공!");
      navigate('/histories');
    });
  };

  return (
    <Page pageName="histories">
      <PageHeader title="연혁 관리" />
      {
        History === null || loading ? (
          <Skeleton />
        ) : (
          <Form 
            name="History"
            autoComplete="off"
            initialValues={History}
            onFinish={editHistory}
          >
            <Form.Item
              label="년도"
              name="year"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="월"
              name="month"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="내용"
              name="contents"
            >
            <EditorComponent />
              {/* <Input /> */}
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">{ submitLoading ? <Spin /> : '저장' }</Button>
            </Form.Item>
          </Form>
        )
      }
    </Page>
  )
};

export default HistoryAdd;