import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Image, Input, PageHeader, Skeleton, Spin, Switch, Upload } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Page from "../../../components/Page";

const HomeRollingEdit = () => {
  const [HomeInformation, setHomeInformation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const addImage = async (item) => {
    const formData = new FormData();
    formData.append('file', item);
    return axios.post('/file',formData).then(res => {
      alert('사진 등록 성공')
      setHomeInformation({...HomeInformation, thumbnail: res.data.url})
    }).catch(err => {
      alert('실패')
    })
  }

  const addImageMobile = async (item) => {
    const formData = new FormData();
    formData.append('file', item);
    return axios.post('/file',formData).then(res => {
      alert('사진 등록 성공')
      setHomeInformation({...HomeInformation, mobileThumbnail: res.data.url})
    }).catch(err => {
      alert('실패')
    })
  }

  const deleteItem = (data) => {
    setSubmitLoading(true);
    axios({
      method: 'DELETE',
      url: `/admin/rolling/${id}`,
    //   data: data
    }).then((res) => {
      if (res.data.success) {
        alert('삭제 성공!');
        setSubmitLoading(false);
        navigate('/information/home')
      }
    });
  };

  const editHomeInformation = (data) => {
    if(isNaN(parseInt(data.orders))){
      alert("순서 부분을 숫자로 입력해주세요 :)")
    }else{
      setSubmitLoading(true);
    axios({
      method: 'POST',
      url: `admin/rolling/${id}`,
      data: {...HomeInformation, orders: parseInt(HomeInformation.orders)}
    }).then((res) => {
      if (res.data.success) {
        alert('변경 완료!');
        setSubmitLoading(false);
        navigate('/information/home')
      }
    });
    }
  };

  useEffect(() => {
    axios({
      method: 'GET',
      url: `/admin/rolling/${id}`
    }).then(res => {
      if (res.data.success) {
        setHomeInformation(res.data.banner);
        setLoading(false);
      }
    })
  }, [id]);

  return (
    <Page pageName="homeInformation">
      <PageHeader title="롤링 배너 확인" />
      {
        HomeInformation === null || loading ? (
          <Skeleton />
        ) : (
          <Form 
            name="homeInformation"
            autoComplete="off"
            initialValues={HomeInformation}
            onFinish={editHomeInformation}
          >
            <Form.Item
              label="순서"
              name="orders"
            >
            <Input value={HomeInformation?.orders} onChange={(e)=>{ setHomeInformation({...HomeInformation, orders: e.target.value})}} />
            </Form.Item>

            <Form.Item
              label="썸네일(1920*533)"
              name="thumbnail"
            >
            {/* <Input/> */}
            <Image width={200} src={HomeInformation?.thumbnail} />
            <input
                type="file"
                onChange={(event) => {
                  if (event.target.files.length) {
                    addImage(event.target.files[0])
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label="모바일 썸네일(360 * 236)"
              name="mobileThumbnail"
            >
            {/* <Input/> */}
            <Image width={200} src={HomeInformation?.mobileThumbnail} />
            <input
                type="file"
                onChange={(event) => {
                  if (event.target.files.length) {
                    addImageMobile(event.target.files[0])
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label="링크 주소"
              name="url"
            >
            <Input value={HomeInformation?.url} onChange={(e)=>{ setHomeInformation({...HomeInformation, url: e.target.value})}} />

            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">{ submitLoading ? <Spin /> : '저장' }</Button>
              <Button style={{'marginLeft':'10px'}} type="primary" danger onClick={() => { deleteItem(id) }}>삭제</Button>
            </Form.Item>
          </Form>
        )
      }
    </Page>
  )
};

export default HomeRollingEdit;