import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Image, Input, PageHeader, Skeleton, Spin, Switch, Upload } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Page from "../../../components/Page";

const HomeInformationEdit = () => {
  const [HomeInformation, setHomeInformation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const deleteItem = (data) => {
    setSubmitLoading(true);
    axios({
      method: 'DELETE',
      url: `/admin/information/home/${id}`,
    //   data: data
    }).then((res) => {
      if (res.data.success) {
        alert('삭제 성공!');
        setSubmitLoading(false);
        navigate('/information/home')
      }
    });
  };

  const editHomeInformation = (data) => {
    if(isNaN(parseInt(data.orders))){
      alert("순서 부분을 숫자로 입력해주세요 :)")
    }else{
      setSubmitLoading(true);
    axios({
      method: 'POST',
      url: `admin/information/home/${id}`,
      data: {...data, orders: parseInt(data.orders)}
    }).then((res) => {
      if (res.data.success) {
        alert('변경 완료!');
        setSubmitLoading(false);
        navigate('/information/home')
      }
    });
    }
  };

  useEffect(() => {
    axios({
      method: 'GET',
      url: `/admin/information/home/${id}`
    }).then(res => {
      if (res.data.success) {
        setHomeInformation(res.data.homeInformation);
        setLoading(false);
      }
    })
  }, [id]);

  return (
    <Page pageName="homeInformation">
      <PageHeader title="홈 데이터 확인" />
      {
        HomeInformation === null || loading ? (
          <Skeleton />
        ) : (
          <Form 
            name="homeInformation"
            autoComplete="off"
            initialValues={HomeInformation}
            onFinish={editHomeInformation}
          >
            <Form.Item
              label="순서"
              name="orders"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="캐치 프레이즈"
              name="upTitle"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="타이틀"
              name="title"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="링크 주소"
              name="url"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="비고"
              name="memo"
            >
            <Input/>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">{ submitLoading ? <Spin /> : '저장' }</Button>
              <Button style={{'marginLeft':'10px'}} type="primary" danger onClick={() => { deleteItem(id) }}>삭제</Button>
            </Form.Item>
          </Form>
        )
      }
    </Page>
  )
};

export default HomeInformationEdit;