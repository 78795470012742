import { Button, PageHeader, Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Page from "../../components/Page";

const AcademyList = () => {
  const [academies, setAcademies] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const columns = [
    {
      title: '번호',
      dataIndex: 'CUSTSEQ',
      key: 'CUSTSEQ'
    },
    {
      title: '학원 이름',
      dataIndex: 'COMNAME',
      key: 'COMNAME'
    },
    {
      title: '주소',
      dataIndex: 'address',
      key: 'address'
    },
    {
      title: '전화번호',
      dataIndex: 'COMTEL',
      key: 'COMTEL'
    },
    {
      title: '',
      render: (_, record) => (
        <Button onClick={() => { navigate(`/academy/${record.Id}`) }}>수정</Button>
      )
    },
  ];

  useEffect(() => {
    axios({
      method: 'GET',
      url: '/admin/academy'
    }).then(res => {
      if (res.data.success) {
        setAcademies(res.data.academies.map((data)=>{return {...data, address: `${data.ADDR1}${data.ADDR2}`}}));
        setLoading(false);
        }
    })
  }, []);

  return (
    <Page pageName="academies">
      <PageHeader title="학원 관리" />
      <Table dataSource={academies} columns={columns}  loading={loading} />
      {/* <Button type="primary" onClick={() => { navigate('/academy/add') }}>추가</Button> */}
    </Page>
  )
};

export default AcademyList;