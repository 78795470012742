import { UploadOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Image, Input, PageHeader, Select, Skeleton, Spin, Upload } from "antd";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Page from "../../components/Page";

const PopupEdit = () => {
  const addImage = async (item) => {
    const formData = new FormData();
    formData.append('file', item);
    return axios.post('/file',formData).then(res => {
      alert('사진 등록 성공')
      setBenefit({...Benefit, thumbmail: res.data.url})
    }).catch(err => {
      alert('실패')
    })
  }

  const addImageMobile = async (item) => {
    const formData = new FormData();
    formData.append('file', item);
    return axios.post('/file',formData).then(res => {
      alert('사진 등록 성공')
      setBenefit({...Benefit, mobileThumbnail: res.data.url})
    }).catch(err => {
      alert('실패')
    })
  }

  const [Benefit, setBenefit] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const editBenefit = (data) => {
    setSubmitLoading(true);
    axios({
      method: 'POST',
      url: `admin/popup/${id}`,
      data: Benefit
    }).then((res) => {
      if (res.data.sucess) {
        setSubmitLoading(false);
        alert("변경 완료!")
        navigate('/popup');
      }
    });
  };

  const deleteItem = (data) => {
    setSubmitLoading(true);
    axios({
      method: 'DELETE',
      url: `/admin/popup/${id}`,
    //   data: data
    }).then((res) => {
      if (res.data.success) {
        alert('삭제 성공!');
        setSubmitLoading(false);
        navigate('/popup')
      }
    });
  };

  useEffect(() => {
    axios({
      method: 'GET',
      url: `/admin/popup/${id}`
    }).then(res => {
      console.log(res.data)
      if (res.data.success) {
        setBenefit({...res.data.popup, startDate: moment(res.data.popup.startDate).format('YYYY-MM-DDTHH:mm'), endDate: moment(res.data.popup.endDate).format('YYYY-MM-DDTHH:mm')});
        setLoading(false);
      }
    })
  }, [id]);

  return (
    <Page pageName="popup">
      <PageHeader title="학원 관리" />
      {
        Benefit === null || loading ? (
          <Skeleton />
        ) : (
          <Form 
            name="popup"
            autoComplete="off"
            initialValues={Benefit}
            onFinish={editBenefit}
          > 
            <Form.Item
              label="링크"
              name="url"
            >
              <Input onChange={(e)=>{setBenefit({...Benefit, url: e.target.value})}}/> 
            </Form.Item>
            <Form.Item
              label="순서"
              name="orders"
            >
              <Input onChange={(e)=>{setBenefit({...Benefit, orders: e.target.value})}}/> 
            </Form.Item>
            <Form.Item
              label="노출"
              name="orders"
            >
            <Checkbox checked={Benefit.expose} onClick={(e)=>{setBenefit({...Benefit, expose: !Benefit.expose})}} />
            </Form.Item>
            <Form.Item
              label="시작일"
              name="startDate"
            >
            <Input type={'datetime-local'} value={Benefit.startDate} onChange={(e)=>{setBenefit({...Benefit, startDate: e.target.value})}}/>
            </Form.Item>
            <Form.Item
              label="종료일"
              name="endDate"
            >
            <Input type={'datetime-local'} value={Benefit.endDate} onChange={(e)=>{setBenefit({...Benefit, endDate: e.target.value})}}/>
            </Form.Item>
            <Form.Item
              label="PC/태블릿 썸네일 (416*416)"
              name="thumbnail"
            >
            <Image width={200} src={Benefit.thumbmail} />
              <input
                type="file"
                onChange={(event) => {
                  if (event.target.files.length) {
                    addImage(event.target.files[0])
                  }
                }}
              />
            </Form.Item>  
            <Form.Item
              label="모바일 썸네일 (284*284)"
              name="mobileThumbnail"
            >
            <Image width={200} src={Benefit.mobileThumbnail} />
              <input
                type="file"
                onChange={(event) => {
                  if (event.target.files.length) {
                    addImageMobile(event.target.files[0])
                  }
                }}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">{ submitLoading ? <Spin /> : '저장' }</Button>
              <Button style={{'marginLeft':'10px'}} type="primary" danger onClick={() => { deleteItem(id) }}>삭제</Button>
            </Form.Item>
          </Form>
        )
      }
    </Page>
  )
};

export default PopupEdit;