import { Button, Form, Image, Input, PageHeader, Select, Skeleton, Spin } from "antd";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Page from "../../../components/Page";

const MumInformationAdd = () => {
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const { TextArea } = Input;
  const [MumInformation, setMumInformation] = useState({type:""});
  const navigate = useNavigate();


  const addTag = (data) => {
    console.log(data)
    // if(!data['type']||!data['content']){
    //   alert('제대로 입력되지 않았습니다!')
    // }else {
      console.log(MumInformation)
      axios({
        url: '/admin/information/mum',
        method: 'POST',
        data: MumInformation
      }).then(() => {
        setLoading(false);
        alert('등록완료!')
        navigate('/information/mum');
      });
    // }
    
  };

  useEffect(() => {
    setMumInformation();
    setLoading(false)
  }, []);

  return (
    <Page pageName="mumInformation">
      <PageHeader title="뮤엠 데이터 관리" />
      {
        MumInformation === null || loading ? (
          <Skeleton />
        ) : (
          <Form 
            name="mumInformation"
            autoComplete="off"
            initialValues={MumInformation}
            onFinish={addTag}
          >
            <Form.Item
              label="데이터 유형"
              name="type"
            >
            <Select onChange={(e)=>{setMumInformation({...MumInformation, type: e})}}>
                <option value="회사명">회사명</option>
                <option value="주소">주소</option>
                <option value="사업자등록번호">사업자등록번호</option>
                <option value="대표자">대표자</option>
                <option value="전화번호">전화번호</option>
                <option value="팩스번호">팩스번호</option>
                <option value="이메일">이메일</option>
                <option value="SNS">SNS</option>
                <option value="대표번호">대표번호</option>
                <option value="운영시간">운영시간</option>
                <option value="휴무일">휴무일</option>
                <option value="공지사항">공지사항</option>
                <option value="페이스북">페이스북</option>
                <option value="인스타그램">인스타그램</option>
                <option value="트위터">트위터</option>
                <option value="블로그">블로그</option>
                <option value="유튜브">유튜브</option>
              </Select>
            {/* <Input onClick={(e)=>{setMumInformation({...MumInformation, type: e.target.value})}} /> */}
            </Form.Item>
            <Form.Item
              label="데이터 내용"
              name="content"
            >
            <TextArea onChange={(e)=>{setMumInformation({...MumInformation, content: e.target.value})}} />
            {/* <Input onChange={(e)=>{setMumInformation({...MumInformation, content: e.target.value})}} /> */}
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">{ submitLoading ? <Spin /> : '저장' }</Button>
            </Form.Item>
          </Form>
        )
      }
    </Page>
  )
};

export default MumInformationAdd;