import { CloseOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Image, Input, PageHeader, Skeleton, Spin, Anchor, Select } from "antd";
import axios from "axios";
import moment from "moment";
import { forwardRef } from "react";
import { useEffect, useState } from "react";
import { Quill } from "react-quill";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../components/Page";
import EditorComponent from "../../components/quill";

const BannerEdit = () => {
  const [Banner, setBanner] = useState({files: [{}]});
  const [imageFile, setImageFile] = useState(null);
  const [ImageUrl, setImageUrl] = useState(null);
  const [content, setContent] = useState('');
  const { TextArea } = Input;
  const { Link } =  Anchor;
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [desc, setDesc] = useState('');
  function onEditorChange(value) {
      console.log(value)
      setBanner({...Banner, content: value})
  }
  const { id } = useParams();
  const navigate = useNavigate();
  const addImage = async (item) => {
    setSubmitLoading(true);
    const formData = new FormData();
    formData.append('file', item);
    return axios.post('/file',formData).then(res => {
      setBanner({...Banner, thumbnail: res.data.url})
      alert('사진 등록 성공')
      setSubmitLoading(true);
    }).catch(err => {
      alert('실패')
    })
  }


  const addImageMobile = async (item) => {
    const formData = new FormData();
    formData.append('file', item);
    return axios.post('/file',formData).then(res => {
      alert('사진 등록 성공')
      setBanner({...Banner, mobileThumbnail: res.data.url, content})
    }).catch(err => {
      alert('실패')
    })
  }



  const addFile = async (item) => {
    setSubmitLoading(true);
    const formData = new FormData();
    formData.append('file', item);
    return axios.post('/file',formData).then(res => {
      const files = { name: res.data.originalName, contents: res.data.url, extend: res.data.extension, success: undefined};
      alert('파일 등록 성공!')
      setSubmitLoading(false);
      setBanner({...Banner, files: [...Banner.files,files]})
    }).catch(err => {
      alert('실패')
    })
  }


  const deleteItem = (data) => {
    setSubmitLoading(true);
    axios({
      method: 'DELETE',
      url: `/admin/banner/${id}`,
    //   data: data
    }).then((res) => {
      if (res.data.success) {
        alert('삭제 성공!');
        setSubmitLoading(false);
        navigate('/banners')
      }
    });
  };

  const editBanner = (data) => {
    setSubmitLoading(true);
    axios({
      method: 'POST',
      url: `/admin/banner/${id}`,
      data: {...Banner, content},
    }).then((res) => {
      if (res.data.success) {
        console.log(Banner)
        alert("변경 완료!");
        setSubmitLoading(false);
        navigate('/banners');
      }
    });
  };

  useEffect(() => {
    axios({
      method: 'GET',
      url: `/admin/banner/${id}`
    }).then(res => {
      if (res.data.success) {
        console.log(res.data.banner.reservation)
        console.log(res.data.banner.reservation.slice(0,16)+'Z')
        console.log(moment(res.data.banner.reservation).format('YYYY-MM-DDTHH:mm'))
        setBanner({...res.data.banner, reservation: moment(res.data.banner.reservation).format('YYYY-MM-DDTHH:mm')});
        setContent(res.data.banner.content)
        setLoading(false);
      }
    })
  }, [id]);

  useEffect(() => {
    console.log(content);
  }, [content]);

  return (
    <Page pageName="banners">
      <PageHeader title="뮤엠 소식 관리" />
      {
        Banner === null || loading ? (
          <Skeleton />
        ) : (
          
          <Form 
            name="Banner"
            autoComplete="off"
            initialValues={Banner}
            onFinish={editBanner}
          >
            <Form.Item
              label="제목"
              name="title"
            >
              <TextArea onChange={(e)=>{setBanner({...Banner, title: e.target.value})}}/>
              {/* <Input onChange={(e)=>{setBanner({...Banner, title: e.target.value})}}/> */}
            </Form.Item>
            <Form.Item
              label="내용"
              name="content"
            >
            </Form.Item>
            <EditorComponent value={content} onChange={setContent} />
            <Form.Item
              label="유형"
              name="type"
            >
              <Select onChange={(e)=>{setBanner({...Banner, type: e})}}>
                <option value="인터뷰">인터뷰</option>
                <option value="이벤트">이벤트</option>
                <option value="보도자료">보도자료</option>
                <option value="공지사항">공지사항</option>
              </Select>
            </Form.Item>
            <Form.Item
              label="PC/태블릿 썸네일 ( px: 389 X 389 )"
              name="thumbnail"
            >
              <Image width={200} src={Banner.thumbnail}  /><br /><br />
              <input
                type="file"
                onChange={(event) => {
                  if (event.target.files.length) {
                    addImage(event.target.files[0])
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label="모바일 썸네일 ( px: 316 X 189 )"
              name="mobileThumbnail"
              style={{'marginLeft':'20px'}}
            >
              {Banner?
              (<Image width={200} src={Banner.mobileThumbnail} />):
              (<div></div>)}
              <input
                type="file"
                onChange={(event) => {
                  if (event.target.files.length) {
                    addImageMobile(event.target.files[0])
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label="게시물 노출 순서"
              name="orders"
            >
            <Input value={Banner.orders} onChange={(e)=>{setBanner({ ...Banner, orders: e.target.value})}}/>
            </Form.Item>
            <Form.Item
              label="게시물 노출 여부"
              name="expose"
            >
            <Checkbox checked={Banner.expose} onChange={(e)=>{setBanner({...Banner, expose: Banner.expose? false: true})}} />
            </Form.Item>

            <Form.Item
              label="추천여부"
              name="recommend"
            >
            <Checkbox checked={Banner.recommend} onChange={(e)=>{setBanner({...Banner, recommend: Banner.recommend? false: true})}} />
            </Form.Item>
            <Form.Item
              label="첨부 파일"
              // name="thumbnail"
            >
              <input
                type="file"
                onChange={(event) => {
                  if (event.target.files.length) {
                    addFile(event.target.files[0])
                  }
                }}
              />
            </Form.Item>
            <Anchor affix={false}>
            {Banner.files.map((item,idx)=>{
              return (
                <div id={idx} key={idx} style={{'display':'flex','alignItems':'center','marginLeft':'10px'}}>
                <CloseOutlined id={idx} key={idx} onClick={(e)=>{setBanner({...Banner, files: Banner.files.filter((data)=>{if(data.contents !== item.contents ){return data;}})});console.log(item)}}/>              
                <Link href={item.contents} title={item.name}   />              
                </div>
              )
            })}
                {/* setBanner({...Banner, files: Banner.files.filter((item)=>{if(item.target)})}) */}
            </Anchor>
            <Form.Item
              label="예약발행"
              name="reservation"
            >
            <Input type={'datetime-local'} value={Banner.orders} onChange={(e)=>{setBanner({ ...Banner, reservation: e.target.value}); console.log(e.target.value)}}/>
            </Form.Item>
            <Form.Item style={{'marginTop':'10px'}}>
              <Button type="primary" htmlType="submit">{ submitLoading ? <Spin /> : '저장' }</Button>
              <Button style={{'marginLeft':'10px'}} type="primary" danger onClick={() => { deleteItem(id) }}>{submitLoading ? <Spin /> : '삭제'}</Button>
            </Form.Item>
          </Form>
        )
      }
    </Page>
  )
};

export default BannerEdit;