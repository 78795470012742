import axios from "axios";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RecoilRoot, useRecoilValue } from "recoil";

import tokenState from "./atoms/tokenState";
import { API_URL } from './constants';

import Login from "./pages/Login";
import AcademyList from "./pages/Academy";
import AcademyEdit from "./pages/Academy/edit";
import AcademyAdd from "./pages/Academy/add";
import HistoryList from "./pages/History";
import HistoryEdit from "./pages/History/edit";
import HistoryAdd from "./pages/History/add";
import BusinessBreifingList from "./pages/BusinessBreifing";
import BusinessBreifingEdit from "./pages/BusinessBreifing/edit";
import BusinessBreifingAdd from "./pages/BusinessBreifing/add";
import BenefitList from "./pages/Benefit";
import BenefitEdit from "./pages/Benefit/edit";
import BenefitAdd from "./pages/Benefit/add";
import BannerList from "./pages/Banner";
import BannerEdit from "./pages/Banner/edit";
import BannerAdd from "./pages/Banner/add";
import ContactList from "./pages/Contact";
import ConsultList from "./pages/Consult";
import ContactEdit from "./pages/Contact/edit";
import ConsultingEdit from "./pages/Consult/edit";
import MumInformationList from "./pages/Information/mum";
import HomeInformationList from "./pages/Information/home";
import MumInformationEdit from "./pages/Information/mum/edit";
import MumInformationAdd from "./pages/Information/mum/add";
import HomeInformationEdit from "./pages/Information/home/edit";
import HomeInformationAdd from "./pages/Information/home/add";
import SurveyAdd from './pages/Survey/add'
import SurveyEdit from './pages/Survey/edit'
import SurveyList from './pages/Survey'
import PolicyEdit from "./pages/Policy";
import HomeRollingEdit from "./pages/Information/home/rollingEdit";
import HomeRollingAdd from "./pages/Information/home/rollingAdd";
import PopupList from "./pages/Popup";
import PopupEdit from "./pages/Popup/edit";
import PopupAdd from "./pages/Popup/add";
import BranchList from "./pages/Branch";
import BranchAdd from "./pages/Branch/add";
import BranchEdit from "./pages/Branch/edit";

const Router = () => {
  const [initialized, setInitialized] = useState(false);
  const token = useRecoilValue(tokenState);

  useEffect(() => {
    if (token) {
      axios.defaults.headers['Authorization'] = token;
    }

    setInitialized(true);
  }, [token]);

  useEffect(() => {
    axios.defaults.baseURL = API_URL;
  }, []);

  if (initialized) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/academies" element={<AcademyList />} />
          <Route path="/academy/:id" element={<AcademyEdit />} />
          <Route path="/academy/add" element={<AcademyAdd />} />
          <Route path="/histories" element={<HistoryList />} />
          <Route path="/history/:id" element={<HistoryEdit />} />
          <Route path="/history/add" element={<HistoryAdd />} />
          <Route path="/surveys" element={<SurveyList />} />
          <Route path="/survey/:id" element={<SurveyEdit />} />
          <Route path="/survey/add" element={<SurveyAdd />} />
          <Route path="/business-breifings" element={<BusinessBreifingList />} />
          <Route path="/business-breifing/:id" element={<BusinessBreifingEdit />} />
          <Route path="/business-breifing/add" element={<BusinessBreifingAdd />} />
          <Route path="/benefits" element={<BenefitList />} />
          <Route path="/benefit/:id" element={<BenefitEdit />} />
          <Route path="/benefit/add" element={<BenefitAdd />} />
          <Route path="/banners" element={<BannerList />} />
          <Route path="/banner" element={<BannerList />} />
          <Route path="/contacts" element={<ContactList />} />
          <Route path="/contacts/:id" element={<ContactEdit />} />
          <Route path="/consultings" element={<ConsultList />} />
          <Route path="/consulting/:id" element={<ConsultingEdit />} />
          <Route path="/banner/:id" element={<BannerEdit />} />
          <Route path="/banner/add" element={<BannerAdd />} />
          <Route path="/information/mum" element={<MumInformationList />} />
          <Route path="/information/mum/:id" element={<MumInformationEdit />} />
          <Route path="/information/mum/add" element={<MumInformationAdd />} />
          <Route path="/information/home" element={<HomeInformationList />} />
          <Route path="/information/home/:id" element={<HomeInformationEdit />} />
          <Route path="/information/home/add" element={<HomeInformationAdd />} />
          <Route path="/rolling/add" element={<HomeRollingAdd />} />
          <Route path="/rolling/:id" element={<HomeRollingEdit />} />
          <Route path="/popup" element={<PopupList />} />
          <Route path="/popup/:id" element={<PopupEdit />} />
          <Route path="/popup/add" element={<PopupAdd />} />
          <Route path="/policy" element={<PolicyEdit />} />
          <Route path="/branches" element={<BranchList />} />
          <Route path="/branch/add" element={<BranchAdd />} />
          <Route path="/branch/:id" element={<BranchEdit />} />
        </Routes>
      </BrowserRouter>
    );
  }

  return <></>;
};

const App = () => {
  return (
    <RecoilRoot>
      <Router />
    </RecoilRoot>
  )
}

export default App;
