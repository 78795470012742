import { Anchor, Button, Checkbox, Image, PageHeader, Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Page from "../../components/Page";

const PopupList = () => {
  const [Benefits, setBenefits] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const {Link } = Anchor;

  const columns = [
    {
      title: '순서',
      dataIndex: 'orders',
      key: 'orders'
    },
    {
      title: '노출 여부',
      render: (_,record) => (
        <Checkbox checked={record.expose} />
      )
    },
    {
      title: '시작일',
      render: (_,record) => (
        <p>{moment(record.startDate).format('YYYY-MM-DD')}</p>
      )
    },
    {
      title: '종료일',
      render: (_,record) => (
        <p>{moment(record.endDate).format('YYYY-MM-DD')}</p>
      )
    },
    {
      title: '대표사진',
      render: (_, record) => (
        <Image style={{'height':'150px'}} src={record.thumbmail} />
      )
    },
    // {
    //   title: 'url',
    //   render: (_, record) => (
    //     <Anchor>
    //         <Link href={record.url} title={record.url} />              
    //     </Anchor>
    //   )
    // },
    {
      title: '',
      render: (_, record) => (
        <Button onClick={() => { navigate(`/popup/${record.id}`) }}>수정</Button>
      )
    },
  ];

  useEffect(() => {
    axios({
      method: 'GET',
      url: '/admin/popups'
    }).then(res => {
      if (res.data.success) {
        setBenefits(res.data.popups);
        setLoading(false);
        }
    })
  }, []);

  return (
    <Page pageName="popup">
      <PageHeader title="팝업 관리" />
      <Table dataSource={Benefits} columns={columns} loading={loading} />
      <Button type="primary" onClick={() => { navigate('/popup/add') }}>추가</Button>
    </Page>
  )
};

export default PopupList;