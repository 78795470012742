import { CloseOutlined } from "@ant-design/icons";
import { Anchor, Button, Form, Input, PageHeader, Skeleton, Spin } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Page from "../../components/Page";
import EditorComponent from "../../components/quill";

const BranchAdd = () => {
const [branch, setBranch] = useState(null);
const [area, setArea] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading]= useState(false);
  const navigate = useNavigate();

  const editBranch = (data) => {
    axios({
      url: '/admin/branch',
      method: 'POST',
      data: {...data, area}
    }).then(() => {
      setLoading(false);
      alert("등록 성공!");
    //   navigate('/branches');
    });
  };

  useEffect(()=>{
    setBranch({});
    setArea([]);
  },[])

  return (
    <Page pageName="branches">
    <PageHeader title="지사 관리" />
    {
      branch === null || loading ? (
        <Skeleton />
      ) : (
        <Form 
          name="histories"
          autoComplete="off"
          initialValues={branch}
          onFinish={editBranch}
        >
          <Form.Item
            label="지사명"
            name="name"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="지사코드"
            name="jisacode"
          >
            <Input />
          </Form.Item>
          {area.map((item,idx)=>{
            return (
              <div id={idx} key={idx} style={{'display':'flex','alignItems':'center','marginLeft':'10px'}}>
              <CloseOutlined id={idx} key={idx} onClick={(e)=>{setArea(area.filter((data)=>{if(data.id !== item.id ){return data;}}));console.log(item)}}/>   
              <Input placeholder="예: 서울특별시" onChange={(e)=>{setArea(area.map((iitem)=>{return {...iitem, city: item.id === iitem.id? e.target.value : iitem.city}}))}} value={item.city} style={{'width':'300px','marginLeft':'30px', 'marginBottom':'2px',}}/>
              <Input placeholder="예: 용산구" onChange={(e)=>{setArea(area.map((iitem)=>{return {...iitem, area: item.id === iitem.id? e.target.value : iitem.area}}))}} value={item.area} style={{'width':'300px','marginLeft':'10px', 'marginBottom':'2px',}}/>
              </div>
            )
          })}
          <Form.Item>
            <Button style={{'marginRight':'10px','marginTop':'30px','backgroundColor':'orange', borderColor:'orange'}} type="primary" onClick={()=>{setArea([...area,{id: area.length}])}}>{ submitLoading ? <Spin /> : '지역 추가' }</Button>
            <Button style={{'marginTop':'30px'}} type="primary" htmlType="submit">{ submitLoading ? <Spin /> : '추가' }</Button>
          </Form.Item>
          <Anchor affix={false}>
              {/* setBanner({...Banner, files: Banner.files.filter((item)=>{if(item.target)})}) */}
          </Anchor>
        </Form>
        
      )
    }
  </Page>
  )
};

export default BranchAdd;