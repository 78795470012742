import { Layout, Menu } from "antd";
import { 
  DatabaseOutlined,
  TagsOutlined,
  UserOutlined
} from '@ant-design/icons';
import { useNavigate } from "react-router-dom";

const { Sider, Content } = Layout;

const Page = ({ children, pageName }) => {
  const navigate = useNavigate();
  window.onbeforeunload = function() {
    return "Leaving this page will reset the wizard";
}
  return (
    <Layout style={{ width: '100%', minHeight: '100vh' }}>
      <Sider>
        <Menu theme="dark" defaultSelectedKeys={[pageName]} mode="inline">
        
          {/* <Menu.Item
            key="academies"
            onClick={() => {
              navigate('/academies');
            }}
          >
            학원 관리
          </Menu.Item> */}
          <Menu.Item
            key="homeInformation"
            onClick={() => {
              navigate('/information/home');
            }}
          >
            배너 관리
          </Menu.Item>
          <Menu.Item
            key="mumInformation"
            onClick={() => {
              navigate('/information/mum');
            }}
          >
            푸터 관리
          </Menu.Item>
          <Menu.Item
            key="banners"
            onClick={() => {
              navigate('/banners');
            }}
          >
            뮤엠 소식 관리
          </Menu.Item>
          <Menu.Item
            key="benefits"
            onClick={() => {
              navigate('/benefits');
            }}
          >
            지원혜택 관리
          </Menu.Item>
          <Menu.Item
            key="business-breifings"
            onClick={() => {
              navigate('/business-breifings');
            }}
          >
            사업설명회 관리
          </Menu.Item>
          <Menu.Item
            key="histories"
            onClick={() => {
              navigate('/histories');
            }}
          >
            연혁 관리
          </Menu.Item>
          {/* <Menu.Item
            key="contacts"
            onClick={() => {
              navigate('/contacts');
            }}
          >
            가맹 문의
          </Menu.Item>
          <Menu.Item
            key="consultings"
            onClick={() => {
              navigate('/consultings');
            }}
          >
            상담 문의
          </Menu.Item> */}
          {/* <Menu.Item
            key="surveys"
            onClick={() => {
              navigate('/surveys');
            }}
          >
            설문지 데이터 관리
          </Menu.Item> */}  

          <Menu.Item
            key="policy"
            onClick={() => {
              navigate('/policy');
            }}
          >
            약관 관리
          </Menu.Item>
          <Menu.Item
            key="popup"
            onClick={() => {
              navigate('/popup');
            }}
          >
            팝업 관리
          </Menu.Item>
          <Menu.Item
            key="branches"
            onClick={() => {
              navigate('/branches');
            }}
          >
            지사 관리
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Content style={{ margin: '16px' }}>
          <div style={{ padding: 24, minHeight: 360, backgroundColor: '#ffffff' }}>
            { children }
          </div>
        </Content>
      </Layout>
    </Layout>
  )
};

export default Page;