import axios from "axios";
import ImageResize  from "quill-image-resize-module-react";
import React from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { API_URL } from '../constants';

const toolbarOptions = [
    ["link", "image", "video"],
    [{ header: [1, 2, 3, false] }],
    ["bold", "italic", "underline", "strike"],
    ["blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
  ]; 
  
  
  // 옵션에 상응하는 포맷, 추가해주지 않으면 text editor에 적용된 스타일을 볼수 없음
  export const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "align",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "background",
    "color",
    "link",
    "image",
    "video",
    "width",
  ];
  
const EditorComponent = ({ value, onChange, placeholder }) => {
    const quillRef = useRef();
    const imageUploadHandler = () => {
        const input = document.createElement('input');

        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.setAttribute('multiple', 'multiple');
        input.click();

        input.addEventListener('change', async () => {
            if (input.files && input.files.length) {
                const files = input.files;
    
                Array.from(files).forEach(file => {
                    const formData = new FormData();
    
                    formData.append('file', file);
    
                try {
                    axios.post(`${API_URL}file`, formData)
                    .then((result) => {
                    if (result.data.url) {
                        const { url } = result.data;
                        const editor = quillRef.current?.getEditor();
        
                        if (editor) {
                        const range = editor.getSelection();
                        
                        if (range !== null) {
                            editor.deleteText(range.index, 1);
                            editor.insertEmbed(range.index, 'image', url);
                            editor.setSelection(range.index + 1);
                        }
                        }
                    } else {
                        window.alert('이미지 업로드에 실패했습니다!');
                    }
                    });
                } catch (error) {
                    window.alert('이미지 업로드에 실패했습니다!');
                }
            });
          }
        });
      };
    const modules = useMemo(() => {
        return {
            toolbar: {
                container: toolbarOptions,
                handlers: {
                image: imageUploadHandler
                }
            },
        }
    },[]);

    Quill.register("modules/imageResize", ImageResize);

    return (
        <div style={{height:'750px'}}>
            <ReactQuill
                style={{height:'700px'}}
                ref={quillRef}
                value={value}
                theme="snow"
                modules={{
                    ...modules,
                    imageResize: {
                        parchment: Quill.import("parchment"),
                        modules: ["Resize", "DisplaySize", "Toolbar"],
                    },
                }}
                formats={formats}
                placeholder={placeholder}
                preserveWhitespace
                onChange={onChange}
            />
        </div>
    );
};

export default EditorComponent