import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Image, Input, PageHeader, Skeleton, Spin, Upload, message, Dropdown, Select } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Page from "../../components/Page";

const SurveyEdit = () => {
  const [loading, setLoading] = useState(true);
  const [Survey, setSurvey] = useState({type:''});
  const  [surveyList, setSurveyList] = useState([]);
  const { TextArea } = Input;
  const [submitLoading, setSubmitLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const deleteItem = (data) => {
    setSubmitLoading(true);
    axios({
      method: 'DELETE',
      url: `/admin/survey/${id}`,
    //   data: data
    }).then((res) => {
      if (res.data.success) {
        alert('삭제 성공!');
        setSubmitLoading(false);
        navigate('/surveys')
      }
    });
  };

  const editSurvey = (data) => {
    setSubmitLoading(true);

    const formData = new FormData();
    const {
      name,
      phoneNumber,
      email,
      education,
      educationStatus,
      educationYear,
      institution,
      institutionName
    } = data;

    formData.append('name', name);
    formData.append('phoneNumber', phoneNumber);
    formData.append('email', email);
    formData.append('education', education);
    formData.append('educationStatus', educationStatus);
    formData.append('educationYear', educationYear);
    formData.append('institution', institution);
    formData.append('institutionName', institutionName);

    axios({
      method: 'POST',
      url: `/admin/survey/${id}`,
      data: data
    }).then((res) => {
      if (res.data.success) {
        alert("수정완료")
        setSubmitLoading(false);
        navigate('/surveys');
      }
    });
  };

  useEffect(()=>{
    if(Survey.type === "가맹 문의"){
      setSurveyList(["유입 경로"])      
    }
    else if(Survey.type === "상담 문의"){ //상담문의
      setSurveyList(["학년 목록","학습 정도"])
    }
  },[Survey.type])

  useEffect(() => {
    axios({
      method: 'GET',
      url: `/admin/Survey/${id}`
    }).then(res => {
      if (res.data.success) {
        console.log(res.data)
        setSurvey(res.data.survey);
        setLoading(false);
      }
    })
  }, [id]);

  return (
    <Page pageName="surveys">
      <PageHeader title="연혁 관리" />
      {
        Survey === null || loading ? (
          <Skeleton />
        ) : (
          <Form 
            name="Survey"
            autoComplete="off"
            initialValues={Survey}
            onFinish={editSurvey}
          >
            <Form.Item
              label="유형"
              name="type"
            >
              <Select onChange={(e)=>{setSurvey({...Survey, type : e })}}>
                <option value="가맹 문의">가맹 문의</option>
                <option value="상담 문의">상담 문의</option>
              </Select>
            </Form.Item>
            <Form.Item
              label="분류"
              name="classification"
            >
              <Select onChange={(e)=>{setSurvey({...Survey, classification: e})}}>
                {
                  surveyList.map((item)=>(<option value={item}>{item}</option>))
                }
              </Select>
            </Form.Item>
            <Form.Item
              label="내용"
              name="content"
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">{ submitLoading ? <Spin /> : '저장' }</Button>
              <Button style={{'marginLeft':'10px'}} type="primary" danger onClick={() => { deleteItem(id) }}>삭제</Button>
            </Form.Item>
          </Form>
        )
      }
    </Page>
  )
};

export default SurveyEdit;