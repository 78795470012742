import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Image, Input, PageHeader, Skeleton, Spin, Upload } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Page from "../../components/Page";

const AcademyEdit = () => {
    const navigate = useNavigate();
  const [Academy, setAcademy] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const { id } = useParams();
  const editAcademy = (data) => {
    setSubmitLoading(true);
    axios({
      method: 'POST',
      url: `/admin/academy/${id}`,
      data: data
    }).then((res) => {
      if (res.data.success) {
        alert("변경 완료!")
        setSubmitLoading(false);
        navigate('/academies');
      }
    });
  };

  const deleteItem = (data) => {
    setSubmitLoading(true);
    axios({
      method: 'DELETE',
      url: `/admin/academy/${id}`,
    //   data: data
    }).then((res) => {
      if (res.data.success) {
        alert('삭제 성공!');
        setSubmitLoading(false);
        navigate('/academies')
      }
    });
  };

  useEffect(() => {
    axios({
      method: 'GET',
      url: `/admin/academy/${id}`
    }).then(res => {
        console.log()
      if (res.data.success) {
        setAcademy(res.data.academy);
        setLoading(false);
      }
    })
  }, [id]);

  return (
    <Page pageName="academies">
      <PageHeader title="학원 관리" />
      {
        Academy === null || loading ? (
          <Skeleton />
        ) : (
          <Form 
            name="Acadmeies"
            autoComplete="off"
            initialValues={Academy}
            onFinish={editAcademy}
            // onChange={()=>{console.log(Academy)}}
          >
            <Form.Item
              label="이름"
              name="name"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="휴대전화번호"
              name="phoneNumber"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="주소"
              name="address"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="상세 주소"
              name="addressDetail"
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">{ submitLoading ? <Spin /> : '저장' }</Button>
              <Button style={{'marginLeft':'10px'}} type="primary" danger onClick={() => { deleteItem(id) }}>삭제</Button>
            </Form.Item>
          </Form>
        )
      }

    </Page>
  )
};

export default AcademyEdit;