import { Button, PageHeader, Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Page from "../../components/Page";

const SurveyList = () => {
  const [Survey, setSurvey] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const columns = [
    {
      title: '순서',
      dataIndex: 'index',
      key: 'index'
    },
    {
      title: '유형',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: '분류',
      dataIndex: 'classification',
      key: 'classification'
    },
    {
      title: '내용',
      dataIndex: 'content',
      key: 'content'
    },
    {
      title: '',
      render: (_, record) => (
        <Button onClick={() => { navigate(`/survey/${record.id}`) }}>수정</Button>
      )
    },
  ];

  useEffect(() => {
    axios({
      method: 'GET',
      url: '/admin/surveys'
    }).then(res => {
      if (res.data.success) {
        console.log(res.data.success)
        setSurvey(res.data.surveys );
        setLoading(false);
        }
    })
  }, []);

  return (
    <Page pageName="surveys">
      <PageHeader title="설문지 데이터 관리" />
      <Table dataSource={Survey} columns={columns} loading={loading} />
      <Button type="primary" onClick={() => { navigate('/survey/add') }}>추가</Button>
    </Page>
  )
};

export default SurveyList;