import { Button, Image, PageHeader, Table } from "antd";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Page from "../../../components/Page";

const MumInformationList = () => {
  const [Information, setInformation] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const columns = [
    {
      title: '번호',
      dataIndex: 'index',
      key: 'index'
    },
    {
      title: '종류',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: '내용',
      dataIndex: 'content',
      key: 'content'
    },
    {
      title: '',
      render: (_, record) => (
        <Button onClick={() => { navigate(`/information/mum/${record.id}`) }}>변경</Button>
      )
    },
  ];

  useEffect(() => {
    axios({
      method: 'GET',
      url: '/admin/information/mum'
    }).then(res => {
      if (res.data.success) {

        console.log(res.data)
        setInformation(res.data.mumInformations.map((item,index)=>{return{...item, index: index+1}}))
        setLoading(false);
        }
    })
  }, []);

  return (
    <Page pageName="mumInformation">
      <PageHeader title="푸터 관리" />
      <Table pagination={false} dataSource={Information} columns={columns} loading={loading} />
      <Button type="primary" onClick={() => { navigate('/information/mum/add') }}>추가</Button>
    </Page>
  )
};

export default MumInformationList;