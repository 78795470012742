import { Layout, Form, Input, Row, Col, Button, Spin, message as Message } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";

import tokenState from '../../atoms/tokenState';

const { Content } = Layout;

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [form] = Form.useForm();
  const [token, setToken] = useRecoilState(tokenState);
  const navigate = useNavigate();

  useEffect(() => {
    if (message) {
      Message.error(message);
    }
  }, [message]);

  useEffect(() => {
    if (token) {
      navigate('/banners');
    }
  }, [token]);

  const onFinish = (data) => {
    setLoading(true);

    const { userId, password } = data;

    axios({
      method: 'POST',
      url: '/admin/login',
      data: {
        accountId: userId,
        password
      }
    }).then((res) => {
      console.log(res.data)
      if (res.data.success) {
        setToken(res.data.token);
      } else {
        setMessage(res.data.message);
        setLoading(false);
        form.resetFields();
      }
    })
  };

  return (
    <Layout style={{ width: '100%', height: '100%' }}>
    <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests"></meta>
      <Content style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Col span={8}>
            <Form 
              form={form}
              name="login"
              autoComplete="off"
              onFinish={onFinish}
            >
              <Form.Item
                label="아이디"
                name="userId"
                rules={[{ required: true, message: '아이디를 입력해주세요.' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="비밀번호"
                name="password"
                rules={[{ required: true, message: '비밀번호를 입력해주세요.' }]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">{ loading ? <Spin /> : '로그인' }</Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
};

export default Login;