import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Image, Input, PageHeader, Skeleton, Spin, Upload } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Page from "../../components/Page";
import moment from "moment";

const BusinessBreifingEdit = () => {
  const [BusinessBreifing, setBusinessBreifing] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const deleteItem = (data) => {
    setSubmitLoading(true);
    axios({
      method: 'DELETE',
      url: `/admin/business-breifing/${id}`,
    //   data: data
    }).then((res) => {
      if (res.data.success) {
        alert('삭제 성공!');
        setSubmitLoading(false);
        navigate('/business-breifings')
      }
    });
  };

  const editBusinessBreifing = (data) => {
    setSubmitLoading(true);
    axios({
      method: 'POST',
      url: `admin/business-breifing/${id}`,
      data
    }).then((res) => {
      if (res.data.success) {
        alert('변경 완료!');
        setSubmitLoading(false);
        navigate('/business-breifings')
      }
    });
  };

  useEffect(() => {
    axios({
      method: 'GET',
      url: `/admin/business-breifing/${id}`
    }).then(res => {
      if (res.data.success) {
        setBusinessBreifing({...res.data.businessBrefing, });
        setLoading(false);
      }
    })
  }, [id]);

  return (
    <Page pageName="business-breifings">
      <PageHeader title="사업설명회 관리" />
      {
        BusinessBreifing === null || loading ? (
          <Skeleton />
        ) : (
          <Form 
            name="businessBreifing"
            autoComplete="off"
            initialValues={BusinessBreifing}
            onFinish={editBusinessBreifing}
          >
            <Form.Item
              label="시간"
              name="time"
            >
              <Input />
              {/* <Input type={'datetime-local'}/> */}
            </Form.Item>
            <Form.Item
              label="지역"
              name="area"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="주소"
              name="address"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="상세 주소"
              name="addressDetail"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="장소"
              name="place"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="전화번호"
              name="phoneNumber"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="추가 전화번호"
              name="extraPhoneNumber"
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">{ submitLoading ? <Spin /> : '저장' }</Button>
              <Button style={{'marginLeft':'10px'}} type="primary" danger onClick={() => { deleteItem(id) }}>삭제</Button>
            </Form.Item>
          </Form>
        )
      }
    </Page>
  )
};

export default BusinessBreifingEdit;