import { Button, Form, Input, PageHeader, Select, Skeleton, Spin } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Page from "../../components/Page";

const SurveyAdd = () => {
  const [loading, setLoading] = useState(false);
  const [Survey, setSurvey] = useState({type:'' });
  const  [surveyList, setSurveyList] = useState([]);
  const [submitLoading, setSubmitLoading]= useState(false);
  const navigate = useNavigate();

  
  const editSurvey = (data) => {
    console.log(data)
    axios({
      url: '/admin/survey',
      method: 'POST',
      data
    }).then(() => {
      setLoading(false);
      alert("등록 성공!");
      navigate('/surveys');
    });
  };

  useEffect(()=>{
    if(Survey.type === "가맹 문의"){
      setSurveyList(["유입 경로"])      
    }
    else if(Survey.type === "상담 문의"){ //상담문의
      setSurveyList(["학년 목록","학습 정도"])
    }
  },[Survey.type])


  return (
    <Page pageName="Surveys">
      <PageHeader title="설문 데이터 관리" />
      {
        Survey === null || loading ? (
          <Skeleton />
        ) : (
          <Form 
            name="Survey"
            autoComplete="off"
            initialValues={Survey}
            onFinish={editSurvey}
          >
            <Form.Item
              label="유형"
              name="type"
            >
              <Select onChange={(e)=>{setSurvey({...Survey, type : e })}}>
                <option value="가맹 문의">가맹 문의</option>
                <option value="상담 문의">상담 문의</option>
              </Select>
            </Form.Item>
            <Form.Item
              label="분류"
              name="classification"
            >
              <Select onChange={(e)=>{setSurvey({...Survey, classification: e})}}>
                {
                  surveyList.map((item)=>(<option value={item}>{item}</option>))
                }
              </Select>
            </Form.Item>
            <Form.Item
              label="내용"
              name="content"
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">{ submitLoading ? <Spin /> : '저장' }</Button>
            </Form.Item>
          </Form>
        )
      }
    </Page>
  )
};

export default SurveyAdd;