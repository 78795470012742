import { CloseOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Image, Input, PageHeader, Skeleton, Spin, Anchor, Select } from "antd";
import axios from "axios";
import moment from "moment";
import { forwardRef } from "react";
import { useEffect, useState } from "react";
import { Quill } from "react-quill";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../components/Page";
import EditorComponent from "../../components/quill";

const PolicyEdit = () => {
  const [Policy, setPolicy] = useState({files: [{}]});
  const [imageFile, setImageFile] = useState(null);
  const [ImageUrl, setImageUrl] = useState(null);
  const [content, setContent] = useState('');
  const { TextArea } = Input;
  const { Link } =  Anchor;
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [desc, setDesc] = useState('');
  function onEditorChange(value) {
      console.log(value)
      setPolicy({...Policy, content: value})
  }
  const { id } = useParams();
  const navigate = useNavigate();

  const editPolicy = (data) => {
    setSubmitLoading(true);
    axios({
      method: 'POST',
      url: `/admin/policy`,
      data: [{id: data.id, content: data.content}],
    }).then((res) => {
      if (res.data.sucess) {
        alert("변경 완료!");
        setSubmitLoading(false);
      }
    });
  };

  useEffect(() => {
    axios({
      method: 'GET',
      url: `/admin/policy`
    }).then(res => {
      if (res.data.success) {
        setPolicy(res.data.policy);
        setContent(res.data.policy.content);
        setLoading(false)
      }
    })
  }, [id]);

  useEffect(() => {
  }, [content]);

  return (
    <Page pageName="policy">
      {
        Policy === null || loading ? (
          <Skeleton />
        ) : (
          <>
          
            {Policy.map((item)=>(<>
            {item.id===1?<PageHeader title="가맹 문의 정책 관리" />:
            item.id===2?<PageHeader title="무료 상담 정책 관리" />:
            <PageHeader title="사업설명회 약관 관리" />}
            <Form 
            name="Policy"
            autoComplete="off"
            initialValues={item}
            onFinish={editPolicy}
          >
            <Form.Item
              name="id"
            >
            </Form.Item>
            <Form.Item
              label="정책 내용"
              name="content"
            >
            <EditorComponent />
            {/* <EditorComponent value={item.content} onChange={''} /> */}
            </Form.Item>
            <Form.Item style={{'marginTop':'10px'}}>
              <Button type="primary" htmlType="submit">{ submitLoading ? <Spin /> : '저장' }</Button>
            </Form.Item>
          </Form></>))}
          </>
        )
      }
    </Page>
  )
};

export default PolicyEdit;