import { Button, Form, Input, PageHeader, Skeleton, Spin } from "antd";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Page from "../../components/Page";

const TagAdd = () => {
    const navigate = useNavigate();
  const [Academy, setAcademy] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const editAcademy = (data) => {
    setSubmitLoading(true);
    axios({
      method: 'POST',
      url: `/admin/academy`,
      data: data
    }).then((res) => {
      if (res.data.success) {
          navigate('/academies')
        setSubmitLoading(false);
        alert('등록 됐습니다!')
      } else {
          alert('주소가 이상해요')
          setSubmitLoading(false);
      }
    });
  };

  useEffect(() => {
    setAcademy();
    setLoading(false)
  }, []);

  return (
    <Page pageName="academies">
      <PageHeader title="학원 관리" />
      {
        Academy === null || loading ? (
          <Skeleton />
        ) : (
          <Form 
            name="Acadmeies"
            autoComplete="off"
            initialValues={Academy}
            onFinish={editAcademy}
            // onChange={()=>{console.log(Academy)}}
          >
            <Form.Item
              label="이름"
              name="name"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="휴대전화번호"
              name="phoneNumber"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="주소"
              name="address"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="상세 주소"
              name="addressDetail"
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">{ submitLoading ? <Spin /> : '저장' }</Button>
            </Form.Item>
          </Form>
        )
      }

    </Page>
  )
};

export default TagAdd;