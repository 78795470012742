import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Image, Input, PageHeader, Select, Skeleton, Spin, Switch, Upload } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Page from "../../../components/Page";

const MumInformationEdit = () => {
  const [MumInformation, setMumInformation] = useState(null);
  const [loading, setLoading] = useState(true);
  const { TextArea } = Input;
  const [submitLoading, setSubmitLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const deleteItem = (data) => {
    setSubmitLoading(true);
    axios({
      method: 'DELETE',
      url: `/admin/information/mum/${id}`,
    //   data: data
    }).then((res) => {
      if (res.data.success) {
        alert('삭제 성공!');
        setSubmitLoading(false);
        navigate('/information/mum')
      }
    });
  };

  const editMumInformation = (data) => {
    setSubmitLoading(true);
    axios({
      method: 'POST',
      url: `admin/information/mum/${id}`,
      data
    }).then((res) => {
      if (res.data.success) {
        alert('변경 완료!');
        setSubmitLoading(false);
        navigate('/information/mum')
      }
    });
  };

  useEffect(() => {
    axios({
      method: 'GET',
      url: `/admin/information/mum/${id}`
    }).then(res => {
      if (res.data.success) {
        console.log(res.data)
        setMumInformation(res.data.mumInformation);
        setLoading(false);
      }
    })
  }, [id]);

  return (
    <Page pageName="mumInformation">
      <PageHeader title="뮤엠 데이터 확인" />
      {
        MumInformation === null || loading ? (
          <Skeleton />
        ) : (
          <Form 
            name="MumInformation"
            autoComplete="off"
            initialValues={MumInformation}
            onFinish={editMumInformation}
          >
            <Form.Item
              label="데이터 유형"
              name="type"
            >
              {/* <Input /> */}
              <Select onChange={(e)=>{setMumInformation({...MumInformation, type: e})}}>
                <option value="회사명">회사명</option>
                <option value="주소">주소</option>
                <option value="사업자등록번호">사업자등록번호</option>
                <option value="대표자">대표자</option>
                <option value="전화번호">전화번호</option>
                <option value="팩스번호">팩스번호</option>
                <option value="이메일">이메일</option>
                <option value="SNS">SNS</option>
                <option value="대표번호">대표번호</option>
                <option value="운영시간">운영시간</option>
                <option value="휴무일">휴무일</option>
                <option value="공지사항">공지사항</option>
              </Select>
            </Form.Item>
            <Form.Item
              label="데이터 내용"
              name="content"
            >
              <TextArea />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">{ submitLoading ? <Spin /> : '저장' }</Button>
              <Button style={{'marginLeft':'10px'}} type="primary" danger onClick={() => { deleteItem(id) }}>삭제</Button>
            </Form.Item>
          </Form>
        )
      }
    </Page>
  )
};

export default MumInformationEdit;