import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Image, Input, PageHeader, Skeleton, Spin, Upload, List, Typography, Switch } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Page from "../../components/Page";
import moment from "moment";

const ConsultingEdit = () => {
  const [Consult, setConsult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const deleteItem = (data) => {
    setSubmitLoading(true);
    axios({
      method: 'DELETE',
      url: `/admin/consulting/${id}`,
    //   data: data
    }).then((res) => {
      if (res.data.success) {
        alert('삭제 성공!');
        setSubmitLoading(false);
        navigate('/consultings')
      }
    });
  };

  const editContact = (data) => {
    console.log(data)
    setSubmitLoading(true);
    axios({
      method: 'POST',
      url: `admin/consulting/${id}`,
      data
    }).then((res) => {
      if (res.data.success) {
        alert('변경 완료!');
        setSubmitLoading(false);
        navigate('/consultings')
      }
    });
  };

  useEffect(() => {
    axios({
      method: 'GET',
      url: `/admin/consulting/${id}`
    }).then(res => {
      if (res.data.success) {
        setConsult({...res.data.consulting});
        setLoading(false);
      }
    })
  }, [id]);

  return (
    <Page pageName="consultings">
      <PageHeader title="상담 문의 확인" />
      {
        Consult === null || loading ? (
          <Skeleton />
        ) : (
          <Form 
            name="Consult"
            autoComplete="off"
            initialValues={Consult}
            onFinish={editContact}
          >
            <Form.Item
              label="상담자 거주 지역"
              name="area"
            >
              {/* <Input type={'datetime-local'}/> */}
              <Input />
            </Form.Item>
            <Form.Item
              label="이름"
              name="name"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="휴대폰 번호"
              name="phoneNumber"
            >
              <Input />
            </Form.Item>
            <Form.Item
              // label="학습 정도"  
              name="studyLevel"
            >
            <List
              header={<div>학습정도</div>}
              // footer={<div>Footer</div>}
              bordered
              dataSource={JSON.parse(JSON.parse(Consult.studyLevel))}
              // dataSource={[{"a":"1","studyLevel":"1"}]}
              renderItem={item => {
                console.log(item)
                return (
                  <List.Item>
                  <Typography.Text mark>{item.level}</Typography.Text> {item.name}-{item.period}
                </List.Item>
                )
              }}
            />
            </Form.Item>
            <Form.Item
              label="상담자 학년"
              name="grade"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="우리 아이 영어교육, 꼭 이것만은!"
              name="inquiry"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="처리 여부"
              name="done"
            >
                <Switch style={{'marginLeft':'20px'}} checked={Consult.done} onClick={()=>{ setConsult({...Consult, done: Consult.done?false:true})}}/>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">{ submitLoading ? <Spin /> : '저장' }</Button>
              <Button style={{'marginLeft':'10px'}} type="primary" danger onClick={() => { deleteItem(id) }}>삭제</Button>
            </Form.Item>
          </Form>
        )
      }
    </Page>
  )
};

export default ConsultingEdit;