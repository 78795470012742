import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Image, Input, PageHeader, Skeleton, Spin, Switch, Upload } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Page from "../../components/Page";
import moment from "moment";

const ContactEdit = () => {
  const [Contact, setContact] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const deleteItem = (data) => {
    setSubmitLoading(true);
    axios({
      method: 'DELETE',
      url: `/admin/contact/${id}`,
    //   data: data
    }).then((res) => {
      if (res.data.success) {
        alert('삭제 성공!');
        setSubmitLoading(false);
        navigate('/contacts')
      }
    });
  };

  const editContact = (data) => {
    setSubmitLoading(true);
    axios({
      method: 'POST',
      url: `admin/contact/${id}`,
      data
    }).then((res) => {
      if (res.data.success) {
        alert('변경 완료!');
        setSubmitLoading(false);
        navigate('/contacts')
      }
    });
  };

  useEffect(() => {
    axios({
      method: 'GET',
      url: `/admin/contact/${id}`
    }).then(res => {
      if (res.data.success) {
        console.log(res.data)
        setContact({...res.data.contact, howToKnow: res.data.contact.howToKnow.split('....').join(' / ')});
        setLoading(false);
      }
    })
  }, [id]);

  return (
    <Page pageName="contacts">
      <PageHeader title="가맹 문의 확인" />
      {
        Contact === null || loading ? (
          <Skeleton />
        ) : (
          <Form 
            name="Contact"
            autoComplete="off"
            initialValues={Contact}
            onFinish={editContact}
          >
            <Form.Item
              label="가맹 희망 지역"
              name="area"
            >
              {/* <Input type={'datetime-local'}/> */}
              <Input />
            </Form.Item>
            <Form.Item
              label="세부 지역"
              name="areaDetail"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="이름"
              name="name"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="휴대폰 번호"
              name="phoneNumber"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="유입 경로"
              name="howToKnow"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="문의 사항"
              name="inquiry"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="처리 여부"
              name="done"
            >
                <Switch style={{'marginLeft':'20px'}} checked={Contact.done} onClick={()=>{ setContact({...Contact, done: Contact.done?false:true})}}/>            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">{ submitLoading ? <Spin /> : '저장' }</Button>
              <Button style={{'marginLeft':'10px'}} type="primary" danger onClick={() => { deleteItem(id) }}>삭제</Button>
            </Form.Item>
          </Form>
        )
      }
    </Page>
  )
};

export default ContactEdit;