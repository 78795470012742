import { Button, Image, PageHeader, Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Page from "../../components/Page";

const BenefitList = () => {
  const [Benefits, setBenefits] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const columns = [
    {
      title: '순서',
      dataIndex: 'orders',
      key: 'orders'
    },
    {
      title: '유형',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: '대표사진',
      // dataIndex: 'address',
      // key: 'address'
      render: (_, record) => {
        return record.type==='마케팅 지원'?
        <Image  style={{'height':'150px'}} src={record.thumbnail} />:
        <video poster={record.videoThumbnail} controls  style={{'height':'200px'}}>
          <source src={record.thumbnail}
            type="video/mp4"/>
        </video>
    }
    },
    {
      title: '',
      render: (_, record) => (
        <Button onClick={() => { navigate(`/benefit/${record.id}`) }}>수정</Button>
      )
    },
  ];

  useEffect(() => {
    axios({
      method: 'GET',
      url: '/admin/benefit'
    }).then(res => {
      if (res.data.success) {
        setBenefits(res.data.benefits);
        setLoading(false);
        }
    })
  }, []);

  return (
    <Page pageName="benefits">
      <PageHeader title="지원 혜택 관리" />
      <Table dataSource={Benefits} columns={columns} loading={loading} />
      <Button type="primary" onClick={() => { navigate('/benefit/add') }}>추가</Button>
    </Page>
  )
};

export default BenefitList;