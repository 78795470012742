import { Button, Form, Image, Input, PageHeader, Select, Skeleton, Spin } from "antd";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Page from "../../components/Page";

const BenefitAdd = () => {
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [Benefit, setBenefit] = useState(null);
  const navigate = useNavigate();


  const addImage = async (item) => {
    const formData = new FormData();
    formData.append('file', item);
    return axios.post('/file',formData).then(res => {
      alert('사진 등록 성공')
      setBenefit({...Benefit, thumbnail: res.data.url})
    }).catch(err => {
      alert('실패')
    })
  }

  const addVideoThumbnail = async (item) => {
    const formData = new FormData();
    formData.append('file', item);
    return axios.post('/file',formData).then(res => {
      alert('사진 등록 성공')
      setBenefit({...Benefit, videoThumbnail: res.data.url})
    }).catch(err => {
      alert('실패')
    })
  }

  const addMobileVideoThumbnail = async (item) => {
    const formData = new FormData();
    formData.append('file', item);
    return axios.post('/file',formData).then(res => {
      alert('사진 등록 성공')
      setBenefit({...Benefit, mobileVideoThumbnail: res.data.url})
    }).catch(err => {
      alert('실패')
    })
  }

  const addImageMobile = async (item) => {
    const formData = new FormData();
    formData.append('file', item);
    return axios.post('/file',formData).then(res => {
      alert('사진 등록 성공')
      setBenefit({...Benefit, mobileThumbnail: res.data.url})
    }).catch(err => {
      alert('실패')
    })
  }

  const addTag = (data) => {
    console.log(Benefit)
    if(!Benefit['type']||!Benefit['orders']||!Benefit['thumbnail']){
      alert('제대로 입력되지 않았습니다!')
    }else {
      axios({
        url: '/admin/benefit',
        method: 'POST',
        data: Benefit
      }).then(() => {
        setLoading(false);
        alert('등록완료!')
        navigate('/benefits');
      });
    }
    
  };

  useEffect(() => {
    setBenefit();
    setLoading(false)
  }, []);

  return (
    <Page pageName="benefits">
      <PageHeader title="지원혜택 관리" />
      {
        Benefit === null || loading ? (
          <Skeleton />
        ) : (
          <Form 
            name="benefits"
            autoComplete="off"
            initialValues={Benefit}
            onFinish={addTag}
          >
            <Form.Item
              label="유형"
              name="type"
            >
              <Select onChange={(e)=>{setBenefit({...Benefit, type: e})}}>
                <option value="마케팅 지원">마케팅 지원</option>
                <option value="홍보 영상 지원">홍보 영상 지원</option>
              </Select>
            </Form.Item>

            <Form.Item
              label="순서"
              name="orders"
            >
              <Input onChange={(e)=>{setBenefit({...Benefit, orders: e.target.value})}}/> 
            </Form.Item>
            <Form.Item
              label="마케팅 지원 유형: 썸네일 / 홍보 영상 지원 유형: 영상(PC/태블릿 1698*957)"
              name="thumbnail"
            >
              {Benefit?
                  Benefit.type==='마케팅 지원'?
                      <Image width={200} src={Benefit.thumbnail} />:
                      <video poster={Benefit.videoThumbnail} controls width="250">
                        <source src={Benefit.thumbnail}
                          type="video/mp4"/>
                      </video>
              :<div></div>}
              <input
                type="file"
                onChange={(event) => {
                  if (event.target.files.length) {
                    addImage(event.target.files[0])
                  }
                }}
              />
            </Form.Item>         
            <Form.Item
              label="마케팅 지원 유형: 썸네일 / 홍보 영상 지원 유형: 영상(모바일 232*319)"
              name="mobileThumbnail"
            >
              {Benefit?
                  Benefit.type==='마케팅 지원'?
                      <Image width={200} src={Benefit.mobileThumbnail} />:
                      <video poster={Benefit.mobileVideoThumbnail} controls width="250">
                        <source src={Benefit.mobileThumbnail}
                          type="video/mp4"/>
                      </video>
              :<div></div>}
              <input
                type="file"
                onChange={(event) => {
                  if (event.target.files.length) {
                    addImageMobile(event.target.files[0])
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label="홍보 영상 지원 유형: 썸네일(PC/태블릿 1698*957)"
              name="videoThumbnail"
            >
              <Image width={200} src={Benefit?.videoThumbnail} />
              <input
                type="file"
                onChange={(event) => {
                  if (event.target.files.length) {
                    addVideoThumbnail(event.target.files[0])
                  }
                }}
              />
            </Form.Item>   
            <Form.Item
              label="홍보 영상 지원 유형: 썸네일(모바일 232*319)"
              name="mobileVideoThumbnail"
            >
              <Image width={200} src={Benefit?.mobileVideoThumbnail} />
              <input
                type="file"
                onChange={(event) => {
                  if (event.target.files.length) {
                    addMobileVideoThumbnail(event.target.files[0])
                  }
                }}
              />
            </Form.Item>   

            {/* <Form.Item
              label="대표 사진"
              name="thumbnail"
            >
            </Form.Item> */}
            <Form.Item>
              <Button type="primary" htmlType="submit">{ submitLoading ? <Spin /> : '저장' }</Button>
            </Form.Item>
          </Form>
        )
      }
    </Page>
  )
};

export default BenefitAdd;