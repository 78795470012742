import { Button, Image, PageHeader, Table ,Checkbox} from "antd";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Page from "../../components/Page";

const ConsultList = () => {
  const [Consultings, setConsultings] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const columns = [
    {
      title: '처리여부',
      dataIndex: 'done',
      // key: 'done'
    },
    {
      title: '신청날짜',
      dataIndex: 'createdAt',
      key: 'createdAt'
    },
    {
      title: '지역',
      dataIndex: 'area',
      key: 'area'
    },
    {
      title: '전화번호',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber'
    },
    {
      title: '학년',
      dataIndex: 'grade',
      key: 'grade'
    },
    // {
    //   title: '유입경로',
    //   dataIndex: 'howToKnow',
    //   key: 'howToKnow'
    // },
    {
      title: '',
      render: (_, record) => (
        <Button onClick={() => { navigate(`/consulting/${record.id}`) }}>확인</Button>
      )
    },
  ];

  useEffect(() => {
    axios({
      method: 'GET',
      url: '/admin/consulting'
    }).then(res => {
      if (res.data.success) {
        console.log(res.data)
        setConsultings(res.data.consultingList.map((item)=>{return {...item, createdAt: moment(item.createdAt).format('YYYY-MM-DD')}}));
        setLoading(false);
        }
    })
  }, []);

  return (
    <Page pageName="consultings">
      <PageHeader title="상담 문의 관리" />
      <Table dataSource={Consultings} columns={columns} loading={loading} />
      {/* <Button type="primary" onClick={() => { navigate('/benefit/add') }}>추가</Button> */}
    </Page>
  )
};

export default ConsultList;