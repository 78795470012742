import { Button, Form, Input, PageHeader, Skeleton, Spin } from "antd";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Page from "../../components/Page";

const BusinessBreifingAdd = () => {
  const [BusinessBreifing, setBusinessBreifing] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const navigate = useNavigate();
  const editBusinessBreifing = (data) => {
    axios({
      url: '/admin/business-breifing',
      method: 'POST',
      data
    }).then(() => {
      setLoading(true)
      navigate('/business-breifings');
      alert('등록 완료!')
      setLoading(false);
    });
  };

  return (
    <Page pageName="business-breifings">
      <PageHeader title="사업설명회 추가" />
      <Form 
            name="businessBreifing"
            autoComplete="off"
            initialValues={BusinessBreifing}
            onFinish={editBusinessBreifing}
          >
            <Form.Item
              label="시간"
              name="time"
            >
              <Input />
              {/* <Input type={'datetime-local'}/> */}
            </Form.Item>
            <Form.Item
              label="지역"
              name="area"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="주소"
              name="address"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="상세 주소"
              name="addressDetail"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="장소"
              name="place"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="전화번호"
              name="phoneNumber"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="추가 전화번호"
              name="extraPhoneNumber"
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">{ submitLoading ? <Spin /> : '저장' }</Button>
            </Form.Item>
          </Form>
    </Page>
  )
};

export default BusinessBreifingAdd;