import { Button, Image, PageHeader, Table } from "antd";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Page from "../../components/Page";

const ContactList = () => {
  const [Contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const columns = [
    {
      title: '처리여부',
      dataIndex: 'done',
      key: 'done'
    },
    {
      title: '신청날짜',
      dataIndex: 'createdAt',
      key: 'createdAt'
    },
    {
      title: '지역',
      dataIndex: 'area',
      key: 'area'
    },
    {
      title: '세부 지역',
      dataIndex: 'areaDetail',
      key: 'areaDetail'
    },
    {
      title: '전화번호',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber'
    },
    {
      title: '유입경로',
      dataIndex: 'howToKnow',
      key: 'howToKnow'
    },
    {
      title: '',
      render: (_, record) => (
        <Button onClick={() => { navigate(`/contacts/${record.id}`) }}>확인</Button>
      )
    },
  ];

  useEffect(() => {
    axios({
      method: 'GET',
      url: '/admin/contact'
    }).then(res => {
      if (res.data.success) {
        console.log(res.data)
        setContacts(res.data.contacts.map((item)=>{return {...item, createdAt: moment(item.createdAt).format('YYYY-MM-DD'), howToKnow: item.howToKnow.split('....').join(',') }}));
        setLoading(false);
        }
    })
  }, []);

  return (
    <Page pageName="contacts">
      <PageHeader title="가맹 문의 관리" />
      <Table dataSource={Contacts} columns={columns} loading={loading} />
      {/* <Button type="primary" onClick={() => { navigate('/benefit/add') }}>추가</Button> */}
    </Page>
  )
};

export default ContactList;