import { Button, Form, Image, Input, PageHeader, Select, Skeleton, Spin } from "antd";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Page from "../../../components/Page";

const HomeInformationAdd = () => {
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [HomeInformation, setHomeInformation] = useState({type:""});
  const navigate = useNavigate();


  const addTag = (data) => {
    if(!data['title']||!data['upTitle']||!data['orders']||!data['url']){
      alert('제대로 입력되지 않았습니다!')
    }else {
      axios({
        url: '/admin/information/home',
        method: 'POST',
        data: {...data, orders: parseInt(data.orders)}
      }).then(() => {
        setLoading(false);
        alert('등록완료!')
        navigate('/information/home');
      });
    }
    
  };

  useEffect(() => {
    setHomeInformation();
    setLoading(false)
  }, []);

  return (
    <Page pageName="homeInformation">
      <PageHeader title="홈 데이터 관리" />
      {
        HomeInformation === null || loading ? (
          <Skeleton />
        ) : (
          <Form 
            name="homeInformation"
            autoComplete="off"
            initialValues={HomeInformation}
            onFinish={addTag}
          >
            <Form.Item
              label="순서"
              name="orders"
            >
            <Input />
            </Form.Item>
            <Form.Item
              label="캐치 프레이즈"
              name="upTitle"
            >
            <Input />
            </Form.Item>
            <Form.Item
              label="타이틀"
              name="title"
            >
            <Input/>
            </Form.Item>
            <Form.Item
              label="링크 주소"
              name="url"
            >
            <Input/>
            </Form.Item>
            <Form.Item
              label="비고"
              name="memo"
            >
            <Input/>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">{ submitLoading ? <Spin /> : '저장' }</Button>
            </Form.Item>
          </Form>
        )
      }
    </Page>
  )
};

export default HomeInformationAdd;