import { Button, Checkbox, Input, PageHeader, Select, Table } from "antd";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";

import Page from "../../components/Page";

const BusinessBreifingList = () => {
  const [BusinessBreifings, setBusinessBreifings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [origin, setOrigin] = useState([]);
  const [filter, setFilter] = useState({year: [], month: []});
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [word, setWord] = useState('');
  const [checked, setChecked] = useState([]);
  const [all, setAll] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const fileRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const uploadExcel = (file: File) => {
    const formData = new FormData();
    setUploadLoading(true);
    formData.append('file', file);
    axios({
      method: 'POST',
      url: `/admin/business-breifings`,
      data: formData
    }).then((res) => {
      const { success } = res.data;

      if (success) {
        window.alert('사업설명회가 등록되었습니다!');
        getData();
      }

      setUploadLoading(false);
    })
  };
  const deleteMany = () => {
    if(window.confirm('삭제하시겠습니까')){
      axios({
        method: 'DELETE',
        url: '/admin/business-breifings',
        data: checked
      }).then(res => {
        console.log(res)
        if (res.data.success) {
          alert('삭제 완료!')
          getData();
          }
      })
    }  
  }

  const columns = [
    {
      title: <Checkbox onClick={()=>{if(all){setChecked([]);setAll(false)}else{setChecked([...checked, ...BusinessBreifings]);setAll(true)}}} checked={all} />,
      dataIndex: 'checked',
      render: (_, record)=>{
        return (<Checkbox onClick={()=>{checked.includes(record)
        ?setChecked(checked.filter((item)=>{if(item !== record){return item}}))
        :setChecked([...checked, record])}} checked={checked.includes(record)} />)
      }
    },
    {
      title: '시간',
      dataIndex: 'time',
      key: 'time'
    },
    {
      title: '지역',
      dataIndex: 'area',
      key: 'area'
    },
    {
      title: '장소',
      dataIndex: 'place',
      key: 'place'
    },
    {
      title: '주소',
      dataIndex: 'address',
      key: 'address'
    },
    {
      title: '연락처',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber'
    },
    {
      title: '',
      render: (_, record) => (
        <Button onClick={() => { navigate(`/business-breifing/${record.id}`) }}>수정</Button>
      )
    },
  ];

  const getData = () => {
    axios({
      method: 'GET',
      url: '/admin/business-breifing'
    }).then(res => {
      if (res.data.success) {
        setBusinessBreifings(res.data.businessBreifings.map((data)=>{return {...data, address: `${data.address} ${data.addressDetail}`}}));
        setOrigin(res.data.businessBreifings.map((data)=>{return {...data, address: `${data.address} ${data.addressDetail}`}}));
        setLoading(false);
        }
    })
  }

  useEffect(() => {
    getData();
    setFilter({
      year:['2022년','2023년','2024년','2025년','2026년','2027년','2028년','2029년','2030년','2031년','2032년'],
      month:['1월','2월', '3월','4월','5월','6월','7월','8월','9월','10월','11월','12월']})
  }, []);

  // useEffect(()=>{
  //   setBusinessBreifings(origin.filter((item)=>{if(item?.time.includes(month))return{ item }}))
  // }, [month])

  // useEffect(()=>{
  //   setBusinessBreifings(origin.filter((item)=>{if(item?.time.includes(year))return{ item }}))
  // }, [year])

  // useEffect(()=>{
  //   setBusinessBreifings(origin.filter((item)=>{if(item?.address.includes(word)||item?.area.includes(word))return{ item }}))    
  // },
  // [word])

  useEffect(()=>{
    if(year){
      if(word&&month){
        setBusinessBreifings(origin.filter((item)=>{if((item?.address.includes(word)||item?.area.includes(word))&&item?.time.includes(year))return{ item }&&(item?.time.includes(month))}))
      }
      else if(word){
        setBusinessBreifings(origin.filter((item)=>{if((item?.address.includes(word)||item?.area.includes(word))&&item?.time.includes(year))return{ item }}))
      }
      else if(month){
        setBusinessBreifings(origin.filter((item)=>{if(item?.time.includes(year)&&(item?.time.includes(month)))return{ item }}))
      } else{
        setBusinessBreifings(origin.filter((item)=>{if(item?.time.includes(year))return{ item }}))
      }
    }
    else if(word){
      if(month){
        setBusinessBreifings(origin.filter((item)=>{if((item?.address.includes(word)||item?.area.includes(word))&&item?.time.includes(month))return{ item }}))
      }
      else{
        setBusinessBreifings(origin.filter((item)=>{if(item?.address.includes(word)||item?.area.includes(word))return{ item }}))
      }
    }
  },
  [word,month,year])

  return (
    <Page pageName="business-breifings">
      <PageHeader title="사업설명회 관리" />
      <Button type="primary" style={{'marginRight':'20px', 'backgroundColor':"red", 'borderColor':'red'}} onClick={() => { deleteMany() }}>삭제</Button>
      <Input value={word} onChange={((e)=>{setWord(e.target.value)})} style={{'width':'200px', 'marginRight':'5px'}}/>
      <Select onChange={(e)=>{setYear(e)}} style={{'width':'90px'}}>
      {filter.year.map((item)=>(<option value={item}>{item}</option>))}
      </Select>
      <Select onChange={(e)=>{setMonth(e)}} style={{'width':'70px','marginLeft':'10px'}}>
        {filter.month.map((item)=>(<option value={item}>{item}</option>))}
      </Select>
      <Table dataSource={BusinessBreifings} columns={columns} loading={loading} />
      <input onChange={(event) => {
          if (event.target.files?.length) {
            const file = event.target.files[0];
            uploadExcel(file);
          }
        }} type="file" name="file" id="excelFile" ref={useRef()} style={{ display: 'none' }} />
        <Button style={{'borderColor':'white'}}>              
        <label for="input-file" style={{'padding':"6px 25px",'backgroundColor':"#FF6600",'borderRadius':'4px','color':'white','cursor':'pointer'}}>업로드</label>
        <input
                  type="file"
                  id="input-file"
                  style={{'display':'none'}}
                  onChange={(event) => {
                    if (event.target.files.length) {
                      uploadExcel(event.target.files[0])
                      // addImage(event.target.files[0])
                    }
                  }}
              />    
              </Button>
        {/* }}>{ uploadLoading ? <Button width={16} height={16} type="spin" color="#ffffff" /> : '엑셀 업로드' }</Button> */}

      <CSVLink
        data={[
          ['시간', '지역', '장소', '주소', '연락처'],
          ...BusinessBreifings.map(item => [item.time, item.area, item.place, item.address, item.phoneNumber])
        ]}
      >
        <Button type="primary">엑셀 다운로드</Button>
      </CSVLink>
      <Button type="primary" style={{'marginLeft':"60px"}} onClick={() => { navigate('/business-breifing/add') }}>추가</Button>
    </Page>
  )
};

export default BusinessBreifingList;