import { Button, PageHeader, Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Page from "../../components/Page";

const BranchList = () => {
  const [Branch, setBranch] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const columns = [
    // {
    //   title: '지사 아이디',
    //   dataIndex: 'id',
    //   key: 'id'
    // },
    {
      title: '지사코드',
      dataIndex: 'jisacode',
      key: 'jisacode'
    },
    {
      title: '지사명',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '담당 구역 확인',
      render: (_, record) => (
        <Button onClick={() => { navigate(`/branch/${record.id}`) }}>수정</Button>
      )
    },
  ];

  useEffect(() => {
    axios({
      method: 'GET',
      url: '/admin/branch'
    }).then(res => {
      if (res.data.success) {
        setBranch(res.data.branches);
        setLoading(false);
        }
    })
  }, []);

  return (
    <Page pageName="branches">
      <PageHeader title="지사 관리" />
      <Table dataSource={Branch} columns={columns} loading={loading} />
      <Button type="primary" onClick={() => { navigate('/Branch/add') }}>추가</Button>
    </Page>
  )
};

export default BranchList;