import { Button, PageHeader, Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Page from "../../components/Page";

const HistoryList = () => {
  const [History, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const columns = [
    {
      title: '순서',
      dataIndex: 'index',
      key: 'index'
    },
    {
      title: '연도',
      dataIndex: 'year',
      key: 'year'
    },
    {
      title: '월',
      dataIndex: 'month',
      key: 'month'
    },
    {
      title: '내용',
      render: (_, record) => (
        <div dangerouslySetInnerHTML={{__html: record.contents}}/>)
      // dataIndex: 'contents',
      // key: 'contents'
    },
    {
      title: '',
      render: (_, record) => (
        <Button onClick={() => { navigate(`/history/${record.id}`) }}>수정</Button>
      )
    },
  ];

  useEffect(() => {
    axios({
      method: 'GET',
      url: '/admin/history'
    }).then(res => {
      if (res.data.success) {
        setHistory(res.data.histories.map((data,idx)=>{return{...data, index: idx+1}}) );
        setLoading(false);
        }
    })
  }, []);

  return (
    <Page pageName="histories">
      <PageHeader title="연혁 관리" />
      <Table dataSource={History} columns={columns} loading={loading} />
      <Button type="primary" onClick={() => { navigate('/history/add') }}>추가</Button>
    </Page>
  )
};

export default HistoryList;