import { Button, Image, PageHeader, Table, Anchor } from "antd";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Page from "../../../components/Page";

const HomeInformationList = () => {
  const [HomeInformations, setHomeInformations] = useState([]);
  const [HomeRollings, setHomeRollings] = useState([]);
  const [loading, setLoading] = useState(true);
  const { Link } = Anchor;
  const navigate = useNavigate();

  const columns = [
    {
      title: '노출 순서',
      dataIndex: 'orders',
      key: 'orders'
    },
    {
      title: '캐치 프레이즈',
      dataIndex: 'upTitle',
      key: 'upTitle'
    },
    {
      title: '내용',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: '링크 주소',
      render: (_, record) => (
        <Anchor>
            <Link href={record.url} title={record.url} />              
        </Anchor>
      )
    },
    {
      title: '',
      render: (_, record) => (
        <Button onClick={() => { navigate(`/information/home/${record.id}`) }}>확인</Button>
      )
    },
  ];

  const rollingColumns = [
    {
      title: '노출 순서',
      dataIndex: 'orders',
      key: 'orders'
    },
    {
      title: '썸네일',
      render: (_,record) => (
        <Image  style={{'height':'150px'}} src={record.thumbnail} />
      )
      // dataIndex: 'thumbnail',
      // key: 'thumbnail'
    },  
    {
      title: '링크 주소',
      render: (_, record) => (
        <Anchor>
            <Link href={record.url} title={record.url} />              
        </Anchor>
      )
    },
    {
      title: '',
      render: (_, record) => (
        <Button onClick={() => { navigate(`/rolling/${record.id}`) }}>확인</Button>
      )
    },
  ];

  useEffect(() => {
    axios({
      method: 'GET',
      url: '/admin/information/home'
    }).then(res => {
      if (res.data.success) {
        setHomeInformations(res.data.homeInformations);
        setLoading(false);
        }
    })
  }, []);

  useEffect(() => {
    axios({
      method: 'GET',
      url: '/admin/rolling'
    }).then(res => {
      if (res.data.success) {
        console.log(res.data)
        setHomeRollings(res.data.banners);
        setLoading(false);
        }
    })
  }, []);

  return (
    <Page pageName="homeInformation">
      <PageHeader title="배너 관리" />
      <Table dataSource={HomeInformations} columns={columns} loading={loading} />
      <Button type="primary" onClick={() => { navigate('/information/home/add') }}>추가</Button>
      <PageHeader title="롤링 배너 관리" />
      <Table dataSource={HomeRollings} columns={rollingColumns} loading={loading} />
      <Button type="primary" onClick={() => { navigate('/rolling/add') }}>추가</Button>
    </Page>
  )
};

export default HomeInformationList;